import React, { useState } from "react";
import { Grid, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./CompanyStyle";

const useStyles = makeStyles(styles);

export default function AccountTypeInput({
  accountType,
  onChangeHandler,
  selectedMerchants,
  merchantsList,
}) {
  const classes = useStyles();

  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };
  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    setOpen(false);
    onChangeHandler(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel className={classes.label}>Account Type:</FormLabel>
          <RadioGroup
            aria-label="type_account"
            name="type_account"
            value={accountType}
            onChange={onChangeHandler}
          >
            <FormControlLabel
              value="shadow"
              control={
                <Radio color="default" className={classes.customCheckbox} />
              }
              label="Shadow account"
            />
            <FormControlLabel
              value="main"
              control={
                <Radio color="default" className={classes.customCheckbox} />
              }
              label="Main Account"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {accountType === "shadow" && (
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Merchants:</InputLabel>
          <Select
            id="companies_ids"
            name="companies_ids"
            label="merchants"
            className={classes.selectInput}
            value={selectedMerchants}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={handleChange}
            multiple={true}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={findName(merchantsList, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
            // error={merchantsError}
          >
            {merchantsList.map((merchant) => (
              <MenuItem key={merchant.id} value={merchant.id}>
                <Checkbox
                  checked={selectedMerchants.indexOf(merchant.id) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={merchant.name} />
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
        </Grid>
      )}
    </>
  );
}
