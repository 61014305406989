import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import CreateVoucher from "./CreateVoucher";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: "#19793a",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
}));

export default function Vouchers({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  createHandler,
  deleteHandler,
  showVoucher,
  companies,
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete this Vouchers ? </h5>
          <div>
            <Button color="danger" size="lg" onClick={() => deleteHandler(id)}>
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const toolbarButtons = (
    <div>
      <Button color="primary" onClick={() => setOpenCreateModal(true)}>
        Add new Voucher
      </Button>
    </div>
  );
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "ID",
    },
    { id: "company_name", sortable: false, label: "Merchant Name" },
    {
      id: "count_of_voucher_used",
      sortable: true,
      label: "Redeemed",
    },
    {
      id: "count_of_voucher_not_used",
      sortable: true,
      label: "Not Used",
    },
    {
      id: "count_of_voucher_reserved",
      sortable: true,
      label: "Active",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.company_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.count_of_voucher_used}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.count_of_voucher_not_used}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.count_of_voucher_reserved}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="show voucher details">
                    <IconButton
                      aria-label="show"
                      onClick={() => showVoucher(row.id)}
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete vouchers">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete([row.id])}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={8} align="center">
            No Vouchers
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={null}
        toolbarButtons={toolbarButtons}
        showSearchBy={false}
        searchByOptions={null}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
      <Modal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        customStyle={{
          width: "100%",
          maxWidth: "500px",
          maxHeight: "500px",
          padding: 0,
          overflowY: "hidden",
        }}
      >
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>Add New Voucher</span>
          <IconButton
            aria-label="delete"
            onClick={() => setOpenCreateModal(false)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <CreateVoucher companies={companies} createHandler={createHandler} />
        </div>
      </Modal>
    </React.Fragment>
  );
}
