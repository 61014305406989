import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel, Switch } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button";
import CustomInput from "components/Shared/CustomInput/Input";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import ListItemText from "@material-ui/core/ListItemText";

import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string("Enter name").required("Name is required"),
  company: yup
    .string("Enter Recycler company")
    .required("Company name is required"),
});

const RecyclerForm = ({
  isNewUser,
  createUser,
  updateUser,
  userUpdateData,
  branchesList,
}) => {
  const formik = useFormik({
    initialValues: {
      email: isNewUser ? "" : userUpdateData.email,
      name: isNewUser ? "" : userUpdateData.name,
      company: isNewUser ? "" : userUpdateData.company,
      branches_ids: isNewUser ? [] : userUpdateData.branches,
      is_access_all: isNewUser ? false : userUpdateData.is_access_all,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };
  const handleChange = (e) => {
    setOpen(false);
    formik.handleChange(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (values) => {
    if (isNewUser) {
      createUser({
        name: values.name,
        email: values.email,
        company: values.company,
        branches_ids: values.branches_ids,
        is_access_all: values.is_access_all,
      });
    } else {
      updateUser({
        name: values.name,
        email: values.email,
        company: values.company,
        id: userUpdateData.id,
        branches_ids: values.branches_ids,
        is_access_all: values.is_access_all,
      });
    }
  };

  const handleChangeSwitch = () => {
    let change;
    if (formik.values.is_access_all === false) {
      change = {
        target: { name: "is_access_all", value: true },
      };
    } else {
      change = {
        target: { name: "is_access_all", value: false },
      };
    }

    formik.handleChange(change);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Name:
          </FormLabel>
          <CustomInput
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Email:
          </FormLabel>
          {isNewUser ? (
            <CustomInput
              id="email"
              name="email"
              label="Enter Email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          ) : (
            <span style={{ marginLeft: "10px" }}>{formik.values.email}</span>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Company Name (Recycler's Company):
          </FormLabel>
          <CustomInput
            id="company"
            name="company"
            label="Enter Company Name"
            type="text"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            className={classes.switchContainer}
            control={
              <Switch
                classes={{
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={formik.values.is_access_all}
                onChange={handleChangeSwitch}
                name="is_access_all"
              />
            }
            label={
              <InputLabel className={classes.label} style={{ margin: "auto" }}>
                Access to All Branches
              </InputLabel>
            }
          />
        </Grid>

        {!formik.values.is_access_all && (
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Select Branch:</InputLabel>
            <Select
              id="branches_ids"
              name="branches_ids"
              label="Branches"
              className={classes.selectInput}
              value={formik.values.branches_ids}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              onChange={handleChange}
              multiple={true}
              input={<Input />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={findName(branchesList, value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              inputProps={{ "aria-label": "Without label" }}
              // error={merchantsError}
            >
              {branchesList.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  <Checkbox
                    checked={formik.values.branches_ids.indexOf(branch.id) > -1}
                    color="default"
                    className={classes.customCheckbox}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isNewUser ? "Add" : "Edit"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RecyclerForm;
