import React from "react";
import Avatar from "@material-ui/core/Avatar";
import useStyles from "./ClientInfoStyle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import BusinessIcon from "@material-ui/icons/Business";
import LockIcon from "@material-ui/icons/Lock";
import UnLockIcon from "@material-ui/icons/LockOpen";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";

const ClientInfo = ({ client }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <Avatar alt={client.name} src={client.image} className={classes.image}>
          <span style={{ textTransform: "uppercase" }}>{client.name[0]}</span>
        </Avatar>
      </div>
      <div className={classes.infoContainer}>
        <span className={classes.label}>Name:</span>
        <span className={classes.content}>{client.name}</span>
      </div>
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <AlternateEmailIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: "#19793a",
            }}
          />
        </span>
        <span className={classes.content}>{client.email}</span>
      </div>
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <PhoneInTalkIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: "#19793a",
            }}
          />
        </span>
        <a href={`tel:${client.phone}`}>
          <span className={classes.content}>{client.phone}</span>
        </a>
      </div>
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <BusinessIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: "#19793a",
            }}
          />
        </span>
        <span className={classes.content}>
          {client.address ? client.address : "N/A"}
        </span>
      </div>
      <div className={classes.infoFlexContainer}>
        <div className={classes.infoContainer}>
          <span className={classes.label}>Status Activation:</span>
          <span className={classes.content}>
            {client.is_active === "deactivate" ? (
              <CancelIcon
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                  color: "red",
                }}
              />
            ) : (
              <VerifiedUserIcon
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                  color: "#19793a",
                }}
              />
            )}
          </span>
        </div>
        <div className={classes.infoContainer}>
          <span className={classes.label}>Blocked:</span>
          <span className={classes.content}>
            {client.is_blocked === "not_blocked" ? (
              <UnLockIcon
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                  color: "#19793a",
                }}
              />
            ) : (
              <LockIcon
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                  color: "red",
                }}
              />
            )}
          </span>
        </div>
      </div>
      <div className={classes.infoFlexContainer}>
        <div className={classes.infoContainer}>
          <span className={classes.label}>Count collectables:</span>
          <span className={classes.content}>
            {client.count_collectables.length}
          </span>
        </div>
        <div className={classes.infoContainer}>
          <span className={classes.label}>Count vouchers:</span>
          <span className={classes.content}>
            {client.count_vouchers.length}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
