import React from "react";
import useStyles from "./CollectableStyle";

const Main = ({ collectable }) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <span className={classes.label}>Model Name:</span>
        <span className={classes.content}>{collectable.model_name}</span>
      </div>
      <div>
        <span className={classes.label}>Category:</span>
        <span className={classes.content}>{collectable.category}</span>
      </div>
      <div>
        <span className={classes.label}>Sub Category:</span>
        <span className={classes.content}>
          {collectable.sub_category}{" "}
          {collectable.sub_category_other
            ? " - " + collectable.sub_category_other
            : null}
        </span>
      </div>
      <div>
        <span className={classes.label}>Brand:</span>
        <span className={classes.content}>
          {collectable.brand}
          {collectable.barnd_other ? " - " + collectable.barnd_other : null}
        </span>
      </div>
      <div>
        <span className={classes.label}>Number Of Collectable:</span>
        <span className={classes.content}>
          {collectable.number_collectable}
        </span>
      </div>
      <div>
        <span className={classes.label}>Priority:</span>
        <span className={classes.content}>{collectable.priority}</span>
      </div>
      <div className={classes.dimension}>
        <span className={classes.label}>width:</span>
        <span className={classes.content}>
          {collectable.width ? collectable.width : "0"}cm
        </span>
      </div>
      <div className={classes.dimension}>
        <span className={classes.label}>height:</span>
        <span className={classes.content}>
          {collectable.height ? collectable.height : "0"}cm
        </span>
      </div>
      <div className={classes.dimension}>
        <span className={classes.label}>depth:</span>
        <span className={classes.content}>
          {collectable.depth ? collectable.depth : "0"}cm
        </span>
      </div>
    </div>
  );
};

export default Main;
