import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerRange({
  startDate,
  endDate,
  changeStartDate,
  changeEndDate,
}) {
  return (
    <>
      <DatePicker
        isClearable
        filterDate={(d) => {
          return new Date() > d;
        }}
        placeholderText="Select Start Date"
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mmaa"
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        onChange={(date) => changeStartDate(date)}
      />
      <DatePicker
        isClearable
        filterDate={(d) => {
          return new Date() > d;
        }}
        placeholderText="Select End Date"
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mmaa"
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        onChange={(date) => changeEndDate(date)}
      />
    </>
  );
}
