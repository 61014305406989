import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import styles from "./sidebarStyle";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import SidebarSubLink from "./SidebarSubLink";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles(styles);

const SidebarLink = ({ color, route, activeRoute, clickHandler }) => {
  const [showChild, setShowChild] = useState(false);
  const classes = useStyles();
  let activePro = " ";
  let listItemClasses;
  listItemClasses = classNames({
    [" " + classes[color]]: activeRoute(route.layout + route.path),
  });
  const showChildHandler = () => {
    if (route.children) {
      setShowChild((prevShow) => !prevShow);
    } else {
      clickHandler();
    }
  };
  return (
    <div>
      <NavLink
        to={route.children ? "#" : route.layout + route.path}
        className={activePro + classes.item}
        activeClassName="active"
        onClick={showChildHandler}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          {typeof route.icon === "string" ? (
            <Icon className={classNames(classes.itemIcon, classes.whiteFont)}>
              {route.icon}
            </Icon>
          ) : (
            <route.icon
              className={classNames(classes.itemIcon, classes.whiteFont)}
            />
          )}
          <ListItemText
            primary={route.name}
            className={classNames(classes.itemText, classes.whiteFont)}
            disableTypography={true}
          />
          {route.children &&
            (showChild ? (
              <ExpandMore
                className={classNames(classes.itemIcon, classes.whiteFont)}
              />
            ) : (
              <ChevronRightIcon
                className={classNames(classes.itemIcon, classes.whiteFont)}
              />
            ))}
        </ListItem>
      </NavLink>
      {route.children && showChild && (
        <List className={classes.list}>
          {route.children.map((prop, key) => (
            <SidebarSubLink
              parentRoute={route}
              route={prop}
              color={color}
              key={key}
              activeRoute={activeRoute}
              clickHandler={clickHandler}
            />
          ))}
        </List>
      )}
    </div>
  );
};

export default SidebarLink;
