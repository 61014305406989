import * as api from "../api";
import * as actionsType from "./actionsType";

// Clients

export const getUsersClients = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: null });
    const { data } = await api.getUsersClients(formData);
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: data });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getUsersClient = (id, history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_CLIENT, payload: null });
    const { data } = await api.getUsersClient(id);
    dispatch({ type: actionsType.GET_USERS_CLIENT, payload: data.data });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteUsersClients = (idsArray, pagination, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: null });
    await api.deleteUsersClients({ ids: idsArray });
    const { data } = await api.getUsersClients(pagination);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Clients Deleted Successfully" },
    });
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: data });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const changeStatusUsersClients = (
  idsArray,
  pagination,
  history
) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: null });
    await api.changeStatusUsersClients({ ids: idsArray });
    const { data } = await api.getUsersClients(pagination);
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "status changed Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

// Agents or Branch users

export const getUsersBranches = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: null });
    const { data } = await api.getUsersBranches(formData);
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createNewUserBranch = (newUser, formData, history) => async (
  dispatch
) => {
  try {
    await api.createUsersBranches(newUser);
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: null });
    const { data } = await api.getUsersBranches(formData);
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Branch user created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteUserBranch = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteUsersBranches({ ids: idsArray });
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: null });
    const { data } = await api.getUsersBranches(formData);
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "user Branch deleted successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getAllBranches = (history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_ALL_BRANCHES, payload: null });
    const { data } = await api.getAllBranches();
    dispatch({ type: actionsType.GET_ALL_BRANCHES, payload: data });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

// Admins

export const getAdmins = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
    const { data } = await api.getUsersAdmins(formData);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createNewAdmin = (newUser, formData, history) => async (
  dispatch
) => {
  try {
    await api.createUsersAdmins(newUser);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
    const { data } = await api.getUsersAdmins(formData);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "the new admin user has been created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateAdmin = (newUser, formData, history) => async (dispatch) => {
  try {
    await api.UpdateUsersAdmins(newUser);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
    const { data } = await api.getUsersAdmins(formData);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "admin user has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteAdmin = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteUsersAdmins({ ids: idsArray });
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
    const { data } = await api.getUsersAdmins(formData);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "admin user has been Deleted successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

// Agents users for managers only

export const getUsersAgents = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: null });
    const { data } = await api.managerFetchUsers(formData);
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createNewUserAgent = (newUser, formData, history) => async (
  dispatch
) => {
  try {
    await api.managerCreateNewUser(newUser);
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: null });
    const { data } = await api.managerFetchUsers(formData);
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New agent user created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteUserAgent = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.managerDeleteUsers({ ids: idsArray });
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: null });
    const { data } = await api.managerFetchUsers(formData);
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "user Branch deleted successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

// Recyclers

export const getRecyclers = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_USERS_RECYCLERS, payload: null });
    const { data } = await api.getUsersRecyclers(formData);
    dispatch({ type: actionsType.GET_USERS_RECYCLERS, payload: data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createNewRecycler = (newUser, formData, history) => async (
  dispatch
) => {
  try {
    await api.createUsersRecyclers(newUser);
    dispatch(getRecyclers(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "the new Recycler user has been created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateRecycler = (newUser, formData, history) => async (
  dispatch
) => {
  try {
    await api.UpdateUsersRecyclers(newUser);
    dispatch(getRecyclers(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Recycler user has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteRecycler = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteUsersRecyclers({ ids: idsArray });
    dispatch(getRecyclers(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Recycler user has been Deleted successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const changeActiveRecycler = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.changeActiveUsersRecyclers({ ids: idsArray });
    dispatch(getRecyclers(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Recycler user's status has been changed successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
