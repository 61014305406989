import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@material-ui/core";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  name: yup.string("Enter name").required("Name is required"),
  name_ar: yup.string("Enter name arabic").required("Name arabic is required"),
});

const CreateNewUser = ({
  categories,
  brand,
  isCreate,
  createHandler,
  updateHandler,
}) => {
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };

  let category_ids = [];
  if (brand) {
    if (brand.categories.length !== 0) {
      brand.categories.map((cat) => {
        const index = categories.findIndex((el) => el.name === cat);
        if (index !== -1) {
          category_ids.push(categories[index].id);
        }
      });
    }
  }
  const formik = useFormik({
    initialValues: {
      name: brand ? brand.name : "",
      name_ar: brand ? brand.name_ar : "",
      category_ids: category_ids,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useStyles();

  const handleSubmit = (values) => {
    if (isCreate) {
      createHandler(values);
    } else {
      const result = { ...values };
      result.id = brand.id;
      updateHandler(result);
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>
        {/* name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name Arabic:</InputLabel>
          <Input
            id="name_ar"
            name="name_ar"
            label="Enter Name Arabic"
            value={formik.values.name_ar}
            onChange={formik.handleChange}
            error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
            helperText={formik.touched.name_ar && formik.errors.name_ar}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Categories:</InputLabel>
          <Select
            id="category_ids"
            name="category_ids"
            label="Categories"
            className={classes.selectInput}
            value={formik.values.category_ids}
            onChange={formik.handleChange}
            multiple={true}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={findName(categories, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
            // error={merchantsError}
          >
            {categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                <Checkbox
                  checked={formik.values.category_ids.indexOf(cat.id) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={cat.name} />
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateNewUser;
