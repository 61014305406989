import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Table from "components/Manager/Collectables/Collected/Collected";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import { getCollectedManager, archiveAll } from "store/actions/collectables";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
};

const useStyles = makeStyles(styles);

const Collected = () => {
  const collectedData = useSelector((state) => state.collectables.collected);
  const authData = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();

  const [page, setPage] = useState(collectedData ? collectedData.pag.page : 1);
  const [amount, setAmount] = useState(
    collectedData ? collectedData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    collectedData ? collectedData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    collectedData ? collectedData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    collectedData ? collectedData.pag.search_by : "model_name"
  );
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCollectedManager(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  const archiveHandler = (values) => {
    dispatch(
      archiveAll(
        values,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Collected Collectables</h4>
        </CardHeader>
        <CardBody>
          {collectedData ? (
            <Table
              rows={collectedData.data}
              totalRows={collectedData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              showRecycable={showRecycableHandler}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              showArchive={authData.role === "manager" ? true : false}
              archiveRecycable={
                authData.role === "manager" ? archiveHandler : null
              }
            />
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Collected;
