// auth
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

// Home
export const SET_MESSAGE = "SET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const GET_HOME = "GET_HOME";
export const GET_MANAGER_HOME = "GET_MANAGER_HOME";

// users
export const GET_USERS_CLIENTS = "GET_USERS_CLIENTS";
export const GET_USERS_CLIENT = "GET_USERS_CLIENT";
export const GET_USERS_BRANCHES = "GET_USERS_BRANCHES";
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_USERS_ADMINS = "GET_USERS_ADMINS";
export const GET_USERS_AGENTS = "GET_USERS_AGENTS";
export const GET_USERS_AGENTS_MANAGER = "GET_USERS_AGENTS_MANAGER";
export const GET_USERS_RECYCLERS = "GET_USERS_RECYCLERS";

// collectables
export const GET_COLLECTABLES_WAITING = "GET_COLLECTABLES_WAITING";
export const GET_COLLECTABLES_APPROVED = "GET_COLLECTABLES_APPROVED";
export const GET_COLLECTABLES_REJECTED = "GET_COLLECTABLES_REJECTED";
export const GET_COLLECTABLES_EXPIRED = "GET_COLLECTABLES_EXPIRED";
export const GET_COLLECTABLES_ARCHIVED = "GET_COLLECTABLES_ARCHIVED";
export const GET_COLLECTABLES_COLLECTED = "GET_COLLECTABLES_COLLECTED";
export const SET_COLLECTABLES_ARRAY = "SET_COLLECTABLES_ARRAY";
export const GET_COLLECTABLE = "GET_COLLECTABLE";
export const GET_COLLECTABLE_MANAGER = "GET_COLLECTABLE_MANAGER";
export const GET_COLLECTABLES_COMPANIES = "GET_COLLECTABLES_COMPANIES";
export const SEARCH_CODE = "SEARCH_CODE";
export const SET_IS_SEARCHING = "SET_IS_SEARCHING";
export const GET_REJECT_REASON = "GET_REJECT_REASON";

// support
export const GET_MANAGER_SUPPORT = "GET_MANAGER_SUPPORT";
export const GET_SUPPORT = "GET_SUPPORT";

// companies
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const GET_MAIN_COMPANIES = "GET_MAIN_COMPANIES";
export const GET_COMPANY_NUMBERS = "GET_COMPANY_NUMBERS";
export const GET_COMPANY_COLLECTABLES = "GET_COMPANY_COLLECTABLES";
export const SET_COMPANY_COLLECTABLES_ARRAY = "SET_COMPANY_COLLECTABLES_ARRAY";

// branches
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_COMPANIES = "GET_BRANCH_COMPANIES";
export const GET_BRANCH_SUBCATEGORIES = "GET_BRANCH_SUBCATEGORIES";
export const GET_BRANCH_DAYS = "GET_BRANCH_DAYS";
export const GET_BRANCH_ARCHIVED = "GET_BRANCH_ARCHIVED";
export const SET_BRANCH_ARRAY = "SET_BRANCH_ARRAY";
export const GET_BRANCH_COLLECTED = "GET_BRANCH_COLLECTED";
export const GET_BRANCH_NOT_COLLECTED = "GET_BRANCH_NOT_COLLECTED";

// category
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_COMPANIES = "GET_CATEGORIES_COMPANIES";

// subCategory
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_PRIORITIES = "GET_ALL_PRIORITIES";

// brand
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_CATEGORIES = "GET_BRANDS_CATEGORIES";

// Voucher
export const SET_MANAGER_VOUCHER = "SET_MANAGER_VOUCHER";
export const VOUCHER_IS_SEARCHING = "VOUCHER_IS_SEARCHING";
export const GET_VOUCHERS = "GET_VOUCHERS";
export const GET_VOUCHER = "GET_VOUCHER";
export const GET_VOUCHER_COMPANIES = "GET_VOUCHER_COMPANIES";

//update mobile
export const GET_MOBILE_UPDATE = "GET_MOBILE_UPDATE";
