import { defaultFont } from "assets/jss/material-dashboard-react.js";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    margin: "20px 5px"
  },
  mainInfoContainer: {
    padding: "20px"
  },
  contentContainer: {
    margin: "10px 0"
  },
  label: {
    ...defaultFont,
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  content: {
    ...defaultFont,
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  }
}));
