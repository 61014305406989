import * as actionsType from "../actions/actionsType";

const supportReducer = (
  state = { managerSupport: null, support: null },
  action
) => {
  switch (action.type) {
    case actionsType.GET_MANAGER_SUPPORT:
      return { ...state, managerSupport: action.payload };
    case actionsType.GET_SUPPORT:
      return { ...state, support: action.payload };
    default:
      return state;
  }
};

export default supportReducer;
