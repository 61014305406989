import { routesLink } from "routes";

export const useRouteName = () => {
  let name = "";
  routesLink.forEach((route) => {
    if (route.children) {
      route.children.forEach((childRoute) => {
        if (window.location.href.indexOf(childRoute.path) !== -1) {
          name = route.name + " / " + childRoute.name;
        }
      });
    } else if (window.location.href.indexOf(route.path) !== -1) {
      name = route.name;
    }
  });
  return name;
};
