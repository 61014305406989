import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Button from "components/Shared/CustomButtons/Button.js";

//hooks
import { useRouteName } from "hooks";

import styles from "./headerStyle";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const routeName = useRouteName();
  const history = useHistory();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  const openKnowledge = () => {
    history.push("/admin/knowledge-base");
  };
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <span className={classes.title}>{routeName}</span>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Tooltip title="Knowlegde Base">
            <IconButton aria-label="open" onClick={openKnowledge}>
              <CollectionsBookmarkIcon style={{ color: "#191919" }} />
            </IconButton>
          </Tooltip>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
};
