import React from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/Shared/CustomButtons/Button";

const RejectReasonInput = ({ reasonList, rejectHandler, cancelHandler }) => {
  const [selectedReasonId, setSelectedReasonId] = React.useState("7");
  const handleChangeReasonId = (event) => {
    setSelectedReasonId(event.target.value);
  };
  return (
    <div>
      <div>
        <InputLabel
          shrink
          htmlFor="reject-reason-select"
          style={{ textAlign: "start" }}
        >
          Select Reject Reason:
        </InputLabel>
        <NativeSelect
          // className={classes.filterInput}
          value={selectedReasonId}
          onChange={handleChangeReasonId}
          inputProps={{ id: "reject-reason-select" }}
        >
          {reasonList.map((reason, index) => (
            <option key={reason.id} value={reason.id}>
              {reason.message}
            </option>
          ))}
        </NativeSelect>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-arround",
          alignItems: "center",
          width: "100%",
          margin: "20px auto"
        }}
      >
        <Button
          color="danger"
          size="lg"
          onClick={() => rejectHandler(selectedReasonId)}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Reject
        </Button>
        <Button
          color="info"
          size="lg"
          onClick={cancelHandler}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default RejectReasonInput;
