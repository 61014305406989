import {
  defaultFont,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: primaryColor[1],
    color: whiteColor,
  },
  content: {
    flex: "3",
    textAlign: "center",
  },
  title: {
    color: whiteColor,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
}));
