import * as actionsType from "../actions/actionsType";

const brandsReducer = (
  state = {
    categories: null,
    brands: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_BRANDS_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case actionsType.GET_BRANDS:
      return {
        ...state,
        brands: action.payload
      };
    default:
      return state;
  }
};

export default brandsReducer;
