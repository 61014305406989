import * as api from "../api";
import * as actionsType from "./actionsType";

export const removeMessage = () => async (dispatch) => {
  dispatch({
    type: actionsType.REMOVE_MESSAGE,
  });
};

export const getHome = (history) => async (dispatch) => {
  try {
    const { data } = await api.getHome();
    dispatch({ type: actionsType.GET_HOME, payload: data.data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getManagerHome = (history) => async (dispatch) => {
  try {
    const { data } = await api.getManagerHome();
    dispatch({ type: actionsType.GET_MANAGER_HOME, payload: data.data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
