import * as actionsType from "../actions/actionsType";

const usersReducer = (
  state = {
    clients: null,
    selectedClient: null,
    allBranches: null,
    usersBranches: null,
    usersAdmins: null,
    usersAgents: null,
    agentsManager: null,
    usersRecyclers: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_USERS_AGENTS_MANAGER:
      return { ...state, agentsManager: action.payload };
    case actionsType.GET_USERS_AGENTS:
      return { ...state, usersAgents: action.payload };
    case actionsType.GET_USERS_RECYCLERS:
      return { ...state, usersRecyclers: action.payload };
    case actionsType.GET_USERS_ADMINS:
      return { ...state, usersAdmins: action.payload };
    case actionsType.GET_ALL_BRANCHES:
      return { ...state, allBranches: action.payload };
    case actionsType.GET_USERS_BRANCHES:
      return { ...state, usersBranches: action.payload };
    case actionsType.GET_USERS_CLIENTS:
      return { ...state, clients: action.payload };
    case actionsType.GET_USERS_CLIENT:
      return { ...state, selectedClient: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
