import React, { useState, useEffect } from "react";
import Button from "components/Shared/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Logo from "assets/img/logoWhite.png";
import useStyles from "./style";

export default function CustomHeader() {
  const classes = useStyles();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  useEffect(() => {
    const user = localStorage.getItem("token");
    if (user) {
      if (user.length !== 0) {
        setUserLoggedIn(true);
      } else {
        setUserLoggedIn(false);
      }
    } else {
      setUserLoggedIn(false);
    }
  }, [localStorage]);
  return (
    <div className={classes.header}>
      <div className={classes.brand}>
        <Link to="/">
          <img src={Logo} className={classes.logo} />
        </Link>
      </div>
      <div className={classes.loginButtonContainer}>
        {userLoggedIn ? (
          <Button
            component={Link}
            to="/admin"
            color="transparentWhite"
            size="lg"
          >
            Dashboard
          </Button>
        ) : (
          <Button
            component={Link}
            to="/login"
            color="transparentWhite"
            size="lg"
          >
            Login
          </Button>
        )}
      </div>
    </div>
  );
}
