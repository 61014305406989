import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PeopleIcon from "@material-ui/icons/People";
import OfferIcon from "@material-ui/icons/LocalOffer";
import StoreIcon from "@material-ui/icons/Store";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import CategoryIcon from "@material-ui/icons/Category";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import BallotIcon from "@material-ui/icons/Ballot";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
// core components/views for Admin layout

import DashboardPage from "views/Dashboard/Dashboard.js";
import TutorialPage from "views/Admin/Tutorial/Tutorial";
// admin
// Recycables pages
import RecycablesWaiting from "views/Admin/Collectables/Waiting/Waiting";
import RecycablesApproved from "views/Admin/Collectables/Approved/Approved";
import RecycablesRejected from "views/Admin/Collectables/Rejected/Rejected";
import RecycablesExpired from "views/Admin/Collectables/Expired/Expired";
import RecycablesArchived from "views/Admin/Collectables/Archived/Archived";
import Recycable from "views/Admin/Collectables/Collectable/Collectable";
// users pages
import UsersClients from "views/Admin/Users/Clients/Clients";
import UsersClient from "views/Admin/Users/Clients/Client";
import UsersBranches from "views/Admin/Users/Agents/Agents";
import UsersSubAdmin from "views/Admin/Users/SubAdmins/SubAdmins";
import UsersRecyclers from "views/Admin/Users/Recyclers/Recyclers";
// merchants pages
import Merchants from "views/Admin/Merchants/Merchants";
import Merchant from "views/Admin/Merchants/Merchant/Merchant";
import CreateMerchant from "views/Admin/Merchants/Merchant/CreateMerchant";
import MerchantRecycables from "views/Admin/Merchants/Merchant/MerchantRecycables";
import MerchantRecycable from "views/Admin/Merchants/Merchant/MerchantCollectable";
import Branches from "views/Admin/Branches/Branches";
import CreateBranch from "views/Admin/Branches/Branch/CreateBranch";
import Branch from "views/Admin/Branches/Branch/Branch";
import BranchCollected from "views/Admin/Branches/Branch/BranchCollected";
import BranchNotCollected from "views/Admin/Branches/Branch/BranchNotCollected";
import BranchCollectable from "views/Admin/Branches/Branch/BranchCollectable";

import Brands from "views/Admin/Brands/Brands";
import Categories from "views/Admin/Categories/Categories";
import SubCategories from "views/Admin/SubCategories/SubCategories";
import Vouchers from "views/Admin/Vouchers/Vouchers";
import Voucher from "views/Admin/Vouchers/Voucher/Voucher";

// manager
import RecycablesArchivedManager from "views/Manager/Collectables/Archived/Archived";
import RecycablesCollected from "views/Manager/Collectables/Collected/Collected";
import RecycableManager from "views/Manager/Collectables/Collectable/Collectable";
import UsersAgentManager from "views/Manager/Users/Agents/Agents";
import ManagerVouchers from "views/Manager/Vouchers/Vouchers";
import BranchArchived from "views/Admin/Branches/Branch/BranchArchived";

// recycler
import RecyclerBranches from "views/Recycler/Branches/Branches";
import RecyclerCollected from "views/Recycler/Branches/BranchCollected";
import RecyclerCollectable from "views/Recycler/Branches/BranchCollectable";

import MobileUpdate from "views/Admin/MobileUpdate/MobileUpdate";

export const routesLink = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: "Recycables",
    icon: LibraryBooksIcon,
    children: [
      {
        path: "/waiting",
        name: "Waiting For Approve",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
      {
        path: "/collected",
        name: "Collected",
        layout: "/admin",
      },
      {
        path: "/approved",
        name: "Approved",
        layout: "/admin",
      },
      {
        path: "/rejected",
        name: "Rejected",
        layout: "/admin",
      },
      {
        path: "/expired",
        name: "Expired",
        layout: "/admin",
      },
      {
        path: "/show",
        name: "Recycle",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleIcon,
    children: [
      {
        path: "/clients",
        name: "Clients",
        layout: "/admin",
      },
      {
        path: "/branches",
        name: "Branches",
        layout: "/admin",
      },
      {
        path: "/sub-admin",
        name: "Sub Admin",
        layout: "/admin",
      },
      {
        path: "/recyclers",
        name: "Recyclers",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/branch-user",
    name: "Users branch",
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    layout: "/admin",
  },
  {
    path: "/sub-categories",
    name: "Sub Categories",
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Brands",
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    layout: "/admin",
  },
  {
    path: "/merchants",
    name: "Merchants",
    children: [
      {
        path: "/merchants",
        name: "Merchants",
        layout: "/admin",
      },
      {
        path: "/recycables",
        name: "Merchant - Recycables",
        layout: "/admin",
      },
      {
        path: "/edit",
        name: "Edit Merchant",
        layout: "/admin",
      },
      {
        path: "/add",
        name: "New Merchant",
        layout: "/admin",
      },
      {
        path: "/branches",
        name: "Branches",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/knowledge-base",
    name: "Knowledge Base Page",
    layout: "/admin",
  },
  {
    path: "/mobile-update",
    name: "Mobile Update",
    layout: "/admin",
  },
  {
    path: "/recycler",
    name: "Recycler",
    children: [
      {
        path: "/branches",
        name: "Branches",
        layout: "/admin",
      },
      {
        path: "/collected",
        name: "Collected Recycables",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
];

export const adminLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: "Recycables",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/waiting",
        name: "Waiting For Approve",
        layout: "/admin",
      },
      {
        path: "/approved",
        name: "Approved",
        layout: "/admin",
      },
      {
        path: "/rejected",
        name: "Rejected",
        layout: "/admin",
      },
      {
        path: "/expired",
        name: "Expired",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleIcon,
    children: [
      {
        path: "/clients",
        name: "Clients",
        layout: "/admin",
      },
      {
        path: "/branches",
        name: "Branches",
        layout: "/admin",
      },
      {
        path: "/sub-admin",
        name: "Sub Admin",
        layout: "/admin",
      },
      {
        path: "/recyclers",
        name: "Recyclers",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: CategoryIcon,
    layout: "/admin",
  },
  {
    path: "/sub-categories",
    name: "Sub Categories",
    icon: BallotIcon,
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Brands",
    icon: LibraryBooksIcon,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: LoyaltyIcon,
    layout: "/admin",
  },
  {
    path: "/merchants",
    name: "Merchants",
    icon: StoreIcon,
    children: [
      {
        path: "/merchants",
        name: "All Merchants",
        layout: "/admin",
      },
      {
        path: "/branches",
        name: "Branches",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/mobile-update",
    name: "Mobile Update",
    icon: SystemUpdateIcon,
    layout: "/admin",
  },
];

export const subAdminLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: "Recycables",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/waiting",
        name: "Waiting For Approve",
        layout: "/admin",
      },
      {
        path: "/approved",
        name: "Approved",
        layout: "/admin",
      },
      {
        path: "/rejected",
        name: "Rejected",
        layout: "/admin",
      },
      {
        path: "/expired",
        name: "Expired",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
];

export const managerLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: " Recycables Requests",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/collected",
        name: "Collected",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleIcon,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: LoyaltyIcon,
    layout: "/admin",
  },
];

export const agentLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: " Recycables Requests",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/collected",
        name: "Collected",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: LoyaltyIcon,
    layout: "/admin",
  },
];

export const recyclerLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycler/branches",
    name: "Recycler Branches",
    icon: StoreIcon,
    layout: "/admin",
  },
];

export const adminRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/waiting"
        component={RecycablesWaiting}
      />
      <Route
        exact
        path="/admin/recycables/approved"
        component={RecycablesApproved}
      />
      <Route
        exact
        path="/admin/recycables/rejected"
        component={RecycablesRejected}
      />
      <Route
        exact
        path="/admin/recycables/archived"
        component={RecycablesArchived}
      />
      <Route
        exact
        path="/admin/recycables/expired"
        component={RecycablesExpired}
      />
      <Route path="/admin/recycables/show/:id" component={Recycable} />
    </Route>
    <Route path="/admin/users">
      <Route path="/admin/users/clients">
        <Route exact path="/admin/users/clients/" component={UsersClients} />
        <Route path="/admin/users/clients/:id" component={UsersClient} />
      </Route>
      <Route exact path="/admin/users/branches" component={UsersBranches} />
      <Route exact path="/admin/users/sub-admin" component={UsersSubAdmin} />
      <Route exact path="/admin/users/recyclers" component={UsersRecyclers} />
    </Route>
    <Route exact path="/admin/categories" component={Categories} />
    <Route exact path="/admin/brands" component={Brands} />
    <Route exact path="/admin/sub-categories" component={SubCategories} />
    <Route path="/admin/merchants">
      <Route exact path="/admin/merchants/merchants" component={Merchants} />
      <Route exact path="/admin/merchants/add" component={CreateMerchant} />
      <Route path="/admin/merchants/edit/:id" component={Merchant} />
      <Route
        path="/admin/merchants/recycables/:id"
        component={MerchantRecycables}
      />
      <Route
        path="/admin/merchants/recycable/:id"
        component={MerchantRecycable}
      />
      <Route path="/admin/merchants/branches">
        <Route exact path="/admin/merchants/branches" component={Branches} />
        <Route
          exact
          path="/admin/merchants/branches/add"
          component={CreateBranch}
        />
        <Route path="/admin/merchants/branches/edit/:id" component={Branch} />
        <Route
          path="/admin/merchants/branches/collected/:id"
          component={BranchCollected}
        />
        <Route
          path="/admin/merchants/branches/archived/:id"
          component={BranchArchived}
        />
        <Route
          path="/admin/merchants/branches/not-collected/:id"
          component={BranchNotCollected}
        />
        <Route
          path="/admin/merchants/branches/recycable/:id"
          component={BranchCollectable}
        />
      </Route>
    </Route>
    <Route path="/admin/vouchers">
      <Route exact path="/admin/vouchers" component={Vouchers} />
      <Route exact path="/admin/vouchers/:id" component={Voucher} />
    </Route>
    <Route exact path="/admin/knowledge-base" component={TutorialPage} />
    <Route exact path="/admin/mobile-update" component={MobileUpdate} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const subAdminRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/waiting"
        component={RecycablesWaiting}
      />
      <Route
        exact
        path="/admin/recycables/approved"
        component={RecycablesApproved}
      />
      <Route
        exact
        path="/admin/recycables/rejected"
        component={RecycablesRejected}
      />
      <Route
        exact
        path="/admin/recycables/archived"
        component={RecycablesArchived}
      />
      <Route
        exact
        path="/admin/recycables/expired"
        component={RecycablesExpired}
      />
      <Route path="/admin/recycables/show/:id" component={Recycable} />
    </Route>
    <Route exact path="/admin/knowledge-base" component={TutorialPage} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const managerRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/collected"
        component={RecycablesCollected}
      />
      <Route
        exact
        path="/admin/recycables/archived"
        component={RecycablesArchivedManager}
      />
      <Route path="/admin/recycables/show/:id" component={RecycableManager} />
    </Route>
    <Route exact path="/admin/users" component={UsersAgentManager} />
    <Route exact path="/admin/vouchers" component={ManagerVouchers} />
    <Route exact path="/admin/knowledge-base" component={TutorialPage} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const agentRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/collected"
        component={RecycablesCollected}
      />
      <Route
        exact
        path="/admin/recycables/archived"
        component={RecycablesArchivedManager}
      />
      <Route path="/admin/recycables/show/:id" component={RecycableManager} />
    </Route>
    <Route exact path="/admin/vouchers" component={ManagerVouchers} />
    <Route exact path="/admin/knowledge-base" component={TutorialPage} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);
export const recyclerRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycler">
      <Route
        exact
        path="/admin/recycler/branches"
        component={RecyclerBranches}
      />
      <Route
        path="/admin/recycler/collected/:id"
        component={RecyclerCollected}
      />
      <Route
        path="/admin/recycler/recycable/:id"
        component={RecyclerCollectable}
      />
    </Route>
    <Route exact path="/admin/knowledge-base" component={TutorialPage} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);
