import React from "react";
import { Typography, Container, Paper } from "@material-ui/core";
import Header from "components/Shared/CutsomHeader/CustomHeader";
import Footer from "components/Shared/CustomFooter/CustomFooter";
import useStyles from "./awarnessStyle";

export default function Awareness() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "scroll";
  }, []);
  const awarenessData = `<div class="col-12 text">
  <h5>General Info</h5>
  <p>
      “WEEE is a term used to cover all items of electrical and electronic equipment (EEE) and its parts that have been discarded by its owner as waste without the intent of reuse”. The amount of electronic waste (WEEE/WEEE) has increased rapidly over the past years with estimated generation capacity of 20-50 million tonnes annually. WEEE impose a global challenge on both developed and developing worlds due to the complex characteristic of this waste stream, where its constituent includes toxic and other resources that varies significantly by product. Currently electronic waste makes up around 5% of municipal waste worldwide and is counted as the fastest growing component of municipal solid waste stream.
  </p>
  <p>
      Egypt is among top three African countries with the highest WEEE generation in absolute quantities, amounting 0.37 million tons in 2014. With no proper management of WEEE, this signpost adverse environmental and health impact because of the constituents of this complex waste stream which includes hazardous resources. As large quantities are generated per year, not enough are collected/recycled by formal sector, leaving majority of WEEE treated or dumped, according to the informal sector treatment.
  </p>
  <h5>E-waste exposure and health risks</h5>
  <p>With the usage of electrical and electronic equipment (EEE) on the rise, the amount of electrical and electronic waste (e-waste) produced each day is equally growing enormously around the globe. Recycling of valuable elements contained in e-waste such as copper and gold has become a source of income mostly in the informal sector of developing or emerging industrialized countries. However, primitive recycling techniques such as burning cables for retaining the inherent copper expose both adult and child workers as well as their families to a range of hazardous substances. E-waste-connected health risks may result from direct contact with harmful materials such as lead, cadmium, chromium, brominated flame retardants or polychlorinated biphenyls (PCBs), from inhalation of toxic fumes, as well as from accumulation of chemicals in soil, water and food. In addition to its hazardous components, being processed, e-waste can give rise to a number of toxic by-products likely to affect human health. Furthermore, recycling activities such as dismantling of electrical equipment may potentially bear an increased risk of injury. </p>
  <p>Children are especially vulnerable to the health risks that may result from e-waste exposure because of the unique ways in which children interact with the environment, they are likely to receive bigger doses of toxicants, relative to their size, than adults. Diet is an important exposure source, and children eat more food and drink more water per pound of body weight than do adults. Breast milk from mothers at e-waste sites indicates elevated exposure to toxicants, such as dioxin, compared with milk from mothers at a reference site. Frequent hand-to-mouth behaviour in younger children can increase exposure to chemicals from dust or play items. Chemicals can accumulate in children’s bodies because their immature systems are unable to process and excrete some toxic materials effectually.</p>
  <p>Whether the exposure is direct or indirect, the health and environmental effects from many of the individual hazardous substances often found in e-waste are well established from existing studies, including studies in children. Several known developmental neurotoxicants are found in e-waste, such as lead, mercury, cadmium, and brominated flame retardants, which can lead to irreversible cognitive deficits in children and behavioral and motor skill dysfunction across the lifespan. Children may directly encounter hazardous substances in fumes or dust through inhalation, skin contact, or oral intake via dismantling activities they perform themselves or that are performed by others nearby. Indirect exposure routes for children, as well as for highly susceptible fetuses, also involve polluted air and drinking water. Exposure variability among children also depends on parental involvement at processing sites, either in or away from the home, and the daily activities of the child .</p>
  <p>In summary, the health of many people, with particular concern for children, is harmed by the contamination resulting from e-waste. Hazardous substances move from discarded EEE across the environment where people are exposed through air, water, soil, and even the food they eat. Thus the threat of adverse environmental health is immediate in many places that accept and informally handle e-waste.</p>
  <h5>Videos:</h5>
  <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/VMzD-Skbb0E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
  </div>
  <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/Ii867Ou50BQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
  </div>
  <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/3Xq474O9DPM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
  </div>
  <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/D7XiwrUCdSw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
  </div>


  <h5>Some important links:</h5>
  <ul>
      <li><a href="http://mewm-egypt.net/en/category/e-waste/">mewm-egypt</a></li>
      <li><a href="https://www.facebook.com/mewm.egypt/?ref=aymt_homepage_panel&amp;eid=ARDJCgxu0FRko9ri_8VKtmUHx6m5fIAwDcJD67VfljHyv60sRXmfaatBSgGfthJnGqkPYnyQwqBPpHcc">Facebook Page</a></li>
  </ul>
</div>`;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <Container component="main" maxWidth="md">
          <div className={classes.header}>
            <Typography className={classes.title} variant="h4">
              Awareness Messages
            </Typography>
          </div>
          <Paper className={classes.paper} elevation={3}>
            <div
              style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: awarenessData }}
            ></div>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
