import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import Button from "components/Shared/CustomButtons/Button";
import { primaryColor } from "assets/jss/material-dashboard-react";
const ImageUpload = ({ image, setImage, error, setError }) => {
  const [singlePicture, setPicture] = useState({});
  const onDrop = (file, picture) => {
    if (file.length === 0 && picture.length === 0) {
      setError("Please choose an image");
    } else {
      setError(null);
    }
    setImage({ data_url: picture[0], file: file[0] });
  };
  return (
    <div>
      {error && <span style={{ color: "red" }}>{error}</span>}
      <ImageUploader
        singleImage={true}
        withPreview={true}
        labelStyles={error ? { color: "red" } : {}}
        defaultImages={image.data_url ? [image.data_url] : []}
        onChange={onDrop}
        buttonText="Choose image"
        buttonStyles={{ backgroundColor: primaryColor[1], color: "#fff" }}
        label="Max file size: 5mb, accepted: jpg/png"
        imgExtension={[".jpg", ".png"]}
        maxFileSize={5242880}
      />
      {/* <ImageUploading value={images} onChange={setImages} dataURLKey="data_url">
        {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
          <div>
            {image === null ? (
              <Button color="primary" onClick={onImageUpload} {...dragProps}>
                Click or Drop here
              </Button>
            ) : (
              <div>
                <div>
                  <Button color="danger" onClick={() => onImageRemove(0)}>
                    Remove
                  </Button>
                </div>
                <div>
                  <img src={imageList[0].data_url} alt="" width="100" />
                </div>
              </div>
            )}
          </div>
        )}
      </ImageUploading> */}
    </div>
  );
};

export default ImageUpload;
