import { defaultFont } from "assets/jss/material-dashboard-react.js";
import { makeStyles } from "@material-ui/core/styles";
import { FullscreenExit } from "@material-ui/icons";

export default makeStyles((theme) => ({
  container: {
    width: "100%"
  },
  searchInputContainer: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  searchInput: {
    flex: 2
  },
  searchButton: {
    margin: "20px 10px 0"
  }
}));
