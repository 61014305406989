import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
// import DeleteIcon from "@material-ui/icons/Delete";
// import LockIcon from "@material-ui/icons/Lock";
import Search from "@material-ui/icons/Search";
import DatePicker from "./DatePicker";
// core components
import CustomInput from "components/Shared/CustomInput/CustomInput.js";
import Button from "components/Shared/CustomButtons/Button.js";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    marginRight: "20px"
  },
  container: {
    width: "100%",
    display: "block"
  },
  mainContainer: {
    margin: "0 10px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      margin: "10px auto",
      display: "block"
    }
  },
  searchContainer: {
    marginTop: "20px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "75%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block"
    }
  },
  searchInput: {
    margin: "0 10px",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      width: "70%"
    }
  },
  searchInputContainer: {
    margin: "0 10px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%"
    }
  },
  searchByContainer: {
    margin: "0 10px",
    flex: 2,
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "90%"
    }
  },
  searchByInput: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  filterContainer: {
    margin: "20px 10px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "20px auto",
      width: "90%",
      display: "block"
    }
  },
  datePickerContainer: {
    [theme.breakpoints.down("xs")]: {
      margin: "20px auto"
    }
  },
  filterButton: {
    width: "200px",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "80%",
      margin: "10px auto",
      marginBottom: "20px"
    }
  },
  clearButton: {
    width: "15%",
    fontSize: "12px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "80%",
      margin: "10px auto",
      marginBottom: "20px"
    }
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    setSearchHandler,
    searchHandler,
    clearSearchHandler,
    searchValue,
    showFilter,
    selectedSearchBy,
    handleSearchByChange,
    showArchive,
    handleArchive,
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
    handleExport
  } = props;

  const handleSearchBy = (event) => {
    handleSearchByChange(event.target.value);
  };

  return (
    <Toolbar className={classes.root}>
      <div className={classes.container}>
        <div className={classes.mainContainer}>
          {showArchive && (
            <Button
              color="primary"
              size="lg"
              onClick={() => handleArchive()}
              style={{ margin: "0 10px" }}
            >
              Archive All
            </Button>
          )}
          <div className={classes.searchContainer}>
            <div className={classes.searchInputContainer}>
              <CustomInput
                formControlProps={{
                  className: classes.searchInput
                }}
                inputProps={{
                  placeholder: "Search",
                  inputProps: {
                    "aria-label": "Search"
                  },
                  onChange: setSearchHandler,
                  value: searchValue
                }}
                search
                searchHandler={searchHandler}
              />
              <Button
                className={classes.searchButton}
                color="transparentBlack"
                aria-label="search"
                justIcon
                round
                onClick={searchHandler}
              >
                <Search />
              </Button>
            </div>
            <div className={classes.searchByContainer}>
              <InputLabel shrink htmlFor="search-by">
                Search By
              </InputLabel>
              <NativeSelect
                className={classes.searchByInput}
                value={selectedSearchBy}
                onChange={handleSearchBy}
                inputProps={{
                  name: "search-by",
                  id: "search-by"
                }}
              >
                <option value="model_name">Model Name</option>
                <option value="code">Code</option>
                {/* <option value="created_at">Created at</option> */}
              </NativeSelect>
            </div>
            <Button
              className={classes.clearButton}
              color="info"
              onClick={clearSearchHandler}
            >
              clear
            </Button>
          </div>
        </div>
        {showFilter && (
          <div className={classes.filterContainer}>
            <div className={classes.datePickerContainer}>
              <InputLabel shrink htmlFor="filter-company">
                Filter By Date
              </InputLabel>
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
              />
            </div>
            <Button
              color="primary"
              size="lg"
              onClick={() => handleExport()}
              style={{ margin: "0 20px", marginTop: "18px" }}
            >
              Export PDF
            </Button>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
