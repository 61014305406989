import * as api from "../api";
import * as actionsType from "./actionsType";

export const getMobileUpdate = (history) => async (dispatch) => {
  try {
    dispatch({ type: actionsType.GET_MOBILE_UPDATE, payload: null });
    const { data } = await api.getMobileUpdate();
    dispatch({ type: actionsType.GET_MOBILE_UPDATE, payload: data.data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateMobileData = (data, history) => async (dispatch) => {
  try {
    await api.updateMobileData(data);
    dispatch(getMobileUpdate(history));
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
