import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import ColorPicker from "./ColorPicker";
import DiscountTypeInput from "./DiscountTypeInput";
import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import AccountTypeInput from "./AccountTypeInput";
import VoucherType from "./VoucherType";
import SendTypeInput from "./SendTypeInput";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./CompanyStyle";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  name: yup.string("Enter Name").required("Name is required"),
  name_ar: yup.string("Enter Name Arabic").required("Name Arabic is required"),
  address: yup.string("Enter Address").required("Address is required"),
  address_ar: yup
    .string("Enter Address Arabic")
    .required("Address Arabic is required"),
  link_website: yup
    .string("Enter Website Link")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a correct Website Link"
    )
    .required("Website Link is required"),
  phone: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8)
    .required("A phone number is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter Description")
    .required("Description Arabic is required"),
  // logo: company?.logo,
  // icon: company?.icon,
  // background_coupon_en: company?.background_coupon_en,
  // background_coupon_ar: company?.background_coupon_ar,
  // coupon_text_color: company?.coupon_text_color,
  type_discount: yup
    .string("Choose Discount Type")
    .required("Discount Type is required")
    .matches(/(percentage|fixed)/, "Please select on of the discount type"),
  discount_percentage: yup.number().when("type_discount", {
    is: "percentage",
    then: yup
      .number()
      .typeError("Please enter a valid number")
      .positive("dicount percentage can't be less then zero")
      .integer("dicount percentage can't include a decimal point")
      .required("dicount percentage is required"),
  }),
  discount_fixed: yup.number().when("type_discount", {
    is: "fixed",
    then: yup
      .number()
      .typeError("Please enter a valid number")
      .positive("dicount fixed can't be equal or less then zero")
      .integer("dicount fixed can't include a decimal point")
      .required("dicount fixed is required"),
    otherwise: null,
  }),

  type_account: yup
    .string("Choose Account Type")
    .required("Account Type is required")
    .matches(/(main|shadow)/, "Please select on of the account type"),
  validity_period: yup
    .number()
    .typeError("Please enter a valid number")
    .positive("validity period can't be equal or less then zero")
    .integer("validity period can't include a decimal point")
    .required("validity period is required"),
  num_user_voucher: yup
    .number()
    .typeError("Please enter a valid number")
    .integer("number of vouchers can't include a decimal point")
    .required("number of vouchers is required"),
  reminder: yup
    .number()
    .typeError("Please enter a valid number")
    .integer("reminder can't include a decimal point")
    .required("reminder is required"),
  type_send: yup
    .array()
    .min(1, "Please choose at least one of the send types")
    .required("Please choose at least one of the send types"),
  voucher_single_code: yup
    .string("Enter Voucher Single code")
    .when("is_single_voucher", {
      is: 1,
      then: yup
        .string("Enter Voucher Single code")
        .required("Voucher Single Code is required"),
      otherwise: null,
    }),
  count_voucher: yup.number("Enter Voucher Count").when("is_single_voucher", {
    is: 1,
    then: yup
      .number()
      .typeError("Please enter a valid number")
      .positive("Voucher Count can't be equal or less then zero")
      .integer("Voucher Count can't include a decimal point")
      .required("Voucher Count is required"),
    otherwise: null,
  }),
  // companies_ids: company?.companies_ids,
});

const Company = ({
  company,
  merchantsList,
  isCreate,
  handleCreate,
  handleUpdate,
}) => {
  const [logo, setLogo] = useState(
    company
      ? company.logo_full_path
        ? { file: null, data_url: company.logo_full_path }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [icon, setIcon] = useState(
    company
      ? company.icon_full_path
        ? { file: null, data_url: company.icon_full_path }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [background_coupon_en, setbackground_coupon_en] = useState(
    company
      ? company.background_coupon_en_full_path
        ? { file: null, data_url: company.background_coupon_en_full_path }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [background_coupon_ar, setbackground_coupon_ar] = useState(
    company
      ? company.background_coupon_ar_full_path
        ? { file: null, data_url: company.background_coupon_ar_full_path }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoError, setLogoError] = useState(null);
  const [iconError, setIconError] = useState(null);
  const [bckCouponEnError, setBckCouponEnError] = useState(null);
  const [bckCouponArError, setBckCouponArError] = useState(null);
  let companies_ids = [];
  if (company) {
    if (company.shadow_companies) {
      if (company.shadow_companies.length !== 0) {
        company.shadow_companies.map((shadowCom) =>
          companies_ids.push(shadowCom.company_shadow_id)
        );
      }
    }
  }
  const formik = useFormik({
    initialValues: {
      name: company ? company.name : "",
      name_ar: company ? company.name_ar : "",
      address: company ? company.address : "",
      address_ar: company ? company.address_ar : "",
      link_website: company ? company.link_website : "",
      phone: company ? company.phone : "",
      description: company ? company.description : "",
      description_ar: company ? company.description_ar : "",
      coupon_text_color: company
        ? company.coupon_text_color
          ? company.coupon_text_color
          : "#000000"
        : "#000000",
      type_discount: company ? company.type_discount : "percentage",
      discount_percentage: company
        ? company.discount_percentage
          ? company.discount_percentage
          : ""
        : "",
      discount_fixed: company
        ? company.discount_fixed
          ? company.discount_fixed
          : ""
        : "",
      type_account: company ? company.type_account : "main",
      validity_period: company ? company.validity_period : "",
      num_user_voucher: company ? company.num_user_voucher : "",
      type_send: company ? company.type_send : [],
      companies_ids: companies_ids,
      cap: company ? (company.cap ? company.cap : 0) : 0,
      reminder: company ? (company.reminder ? company.reminder : "") : "",
      is_single_voucher: company
        ? company.is_single_voucher
          ? company.is_single_voucher
          : 0
        : 0,
      voucher_single_code: company
        ? company.voucher_single_code
          ? company.voucher_single_code
          : ""
        : "",
      count_voucher: company
        ? company.count_voucher
          ? company.count_voucher
          : 0
        : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const [formData, setFormData] = useState(initialState);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values) => {
    if (isCreate === true) {
      create(values);
    } else {
      update(values);
    }
  };
  const create = (values) => {
    const result = { ...values };
    let error = false;
    if (logoError || iconError || bckCouponEnError || bckCouponArError) {
      error = true;
    }
    if (logo.file === null) {
      setLogoError("Please Enter Logo");
      error = true;
    }
    if (icon.file === null) {
      setIconError("Please Enter Icon");
      error = true;
    }
    if (background_coupon_en.file === null) {
      setBckCouponEnError("Please Enter Coupon Background En");
      error = true;
    }
    if (background_coupon_en.file === null) {
      setBckCouponArError("Please Enter Coupon Background Ar");
      error = true;
    }
    if (error === false) {
      result.logo = logo.file;
      result.icon = icon.file;
      result.background_coupon_en = background_coupon_en.file;
      result.background_coupon_ar = background_coupon_ar.file;
      handleCreate(result);
    }
  };
  const update = (values) => {
    const result = { ...values };
    let error = false;
    if (logoError || iconError || bckCouponEnError || bckCouponArError) {
      error = true;
    }
    if (logo.file !== null) {
      result.logo = logo.file;
    } else if (logo.data_url === null) {
      setLogoError("Enter Logo!");
      error = true;
    }
    if (icon.file !== null) {
      result.icon = icon.file;
    } else if (icon.data_url === null) {
      setIconError("Enter Icon!");
      error = true;
    }
    if (background_coupon_en.file !== null) {
      result.background_coupon_en = background_coupon_en.file;
    } else if (background_coupon_en.data_url === null) {
      error = true;
    }
    if (background_coupon_ar.file !== null) {
      result.background_coupon_ar = background_coupon_ar.file;
    } else if (background_coupon_ar.data_url === null) {
      error = true;
    }

    if (error === false) {
      handleUpdate(result);
    }
  };

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}
          {/* name */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name:</InputLabel>
            <Input
              id="name"
              name="name"
              label="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              type="text"
            />
          </Grid>
          {/* name_ar */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name Arabic:</InputLabel>
            <Input
              id="name_ar"
              name="name_ar"
              label="Enter Name Arabic"
              value={formik.values.name_ar}
              onChange={formik.handleChange}
              error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
              helperText={formik.touched.name_ar && formik.errors.name_ar}
              type="text"
            />
          </Grid>
          {/* address */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address English:</InputLabel>
            <Input
              id="address"
              name="address"
              label="Enter Address English"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              type="text"
            />
          </Grid>
          {/* address_ar */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address Arabic:</InputLabel>
            <Input
              id="address_ar"
              name="address_ar"
              label="Enter Address Arabic"
              value={formik.values.address_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.address_ar && Boolean(formik.errors.address_ar)
              }
              helperText={formik.touched.address_ar && formik.errors.address_ar}
              type="text"
            />
          </Grid>
          {/* link_website */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Website Link:</InputLabel>
            <Input
              id="link_website"
              name="link_website"
              label="Enter Website Link"
              value={formik.values.link_website}
              onChange={formik.handleChange}
              error={
                formik.touched.link_website &&
                Boolean(formik.errors.link_website)
              }
              helperText={
                formik.touched.link_website && formik.errors.link_website
              }
              type="text"
            />
          </Grid>
          {/* phone */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Phone Number:</InputLabel>
            <Input
              id="phone"
              name="phone"
              label="Enter Your phone number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              type="text"
            />
          </Grid>
          {/* Description English */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description English:
            </InputLabel>
            <Input
              id="description"
              name="description"
              label="Enter Description"
              multiline
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              type="text"
            />
          </Grid>
          {/* Description Arabic */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description Arabic:
            </InputLabel>
            <Input
              id="description_ar"
              name="description_ar"
              label="Enter Description Arabic"
              multiline
              value={formik.values.description_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.description_ar &&
                Boolean(formik.errors.description_ar)
              }
              helperText={
                formik.touched.description_ar && formik.errors.description_ar
              }
              type="text"
            />
          </Grid>
          {/* Logo */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Logo:</InputLabel>
            <ImageUpload
              image={logo}
              setImage={setLogo}
              error={logoError}
              setError={setLogoError}
            />
          </Grid>
          {/* icon */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Icon:</InputLabel>
            <ImageUpload
              image={icon}
              setImage={setIcon}
              error={iconError}
              setError={setIconError}
            />
          </Grid>
          {/* Coupons Header */}
          <Grid item xs={12}>
            <h4>Create Coupon</h4>
          </Grid>
          {/* coupon En */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Background Coupon EN:
            </InputLabel>
            <ImageUpload
              image={background_coupon_en}
              setImage={setbackground_coupon_en}
              error={bckCouponEnError}
              setError={setBckCouponEnError}
            />
          </Grid>
          {/* coupon Ar */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Background Coupon AR:
            </InputLabel>
            <ImageUpload
              image={background_coupon_ar}
              setImage={setbackground_coupon_ar}
              error={bckCouponArError}
              setError={setBckCouponArError}
            />
          </Grid>
          {/* text color */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Text Color:</InputLabel>
            <ColorPicker
              textColor={formik.values.coupon_text_color}
              setTextColor={formik.handleChange}
            />
          </Grid>
          {/* discount Type */}
          <Grid item xs={12}>
            <DiscountTypeInput
              discountType={formik.values.type_discount}
              discountPercent={formik.values.discount_percentage}
              discountFixed={formik.values.discount_fixed}
              onChangeHandler={formik.handleChange}
              discountPercentError={
                formik.touched.discount_percentage &&
                Boolean(formik.errors.discount_percentage)
              }
              discountPercentHelperText={
                formik.touched.discount_percentage &&
                formik.errors.discount_percentage
              }
              discountFixedError={
                formik.touched.discount_fixed &&
                Boolean(formik.errors.discount_fixed)
              }
              discountFixedHelperText={
                formik.touched.discount_fixed && formik.errors.discount_fixed
              }
            />
          </Grid>
          {/* cap value */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Cap Value:</InputLabel>
            <Input
              id="cap"
              name="cap"
              label="Enter Cap"
              required={false}
              value={formik.values.cap}
              onChange={formik.handleChange}
              type="number"
            />
          </Grid>
          {/* account type */}
          <Grid item xs={12}>
            <AccountTypeInput
              accountType={formik.values.type_account}
              selectedMerchants={formik.values.companies_ids}
              onChangeHandler={formik.handleChange}
              merchantsList={merchantsList}
            />
          </Grid>
          {/* Voucher type */}
          <Grid item xs={12}>
            <VoucherType
              isSingleVoucher={formik.values.is_single_voucher}
              onChangeHandler={formik.handleChange}
              voucherSingleCode={formik.values.voucher_single_code}
              voucherError={
                formik.touched.voucher_single_code &&
                Boolean(formik.errors.voucher_single_code)
              }
              voucherHelperText={
                formik.touched.voucher_single_code &&
                formik.errors.voucher_single_code
              }
              voucherCount={formik.values.count_voucher}
              voucherCountError={
                formik.touched.count_voucher &&
                Boolean(formik.errors.count_voucher)
              }
              voucherCountHelperText={
                formik.touched.count_voucher && formik.errors.count_voucher
              }
            />
          </Grid>
          {/* validty period */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Request Validity Period:
            </InputLabel>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                id="validity_period"
                name="validity_period"
                label="Enter validity_period"
                value={formik.values.validity_period}
                onChange={formik.handleChange}
                error={
                  formik.touched.validity_period &&
                  Boolean(formik.errors.validity_period)
                }
                helperText={
                  formik.touched.validity_period &&
                  formik.errors.validity_period
                }
                type="number"
              />
              <span style={{ padding: "5px" }}>Days</span>
            </div>
          </Grid>
          {/* number of limited user voucher per month */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Number of limited user voucher per month:
            </InputLabel>
            <Input
              id="num_user_voucher"
              name="num_user_voucher"
              label="Enter Number of limit"
              value={formik.values.num_user_voucher}
              onChange={formik.handleChange}
              error={
                formik.touched.num_user_voucher &&
                Boolean(formik.errors.num_user_voucher)
              }
              helperText={
                formik.touched.num_user_voucher &&
                formik.errors.num_user_voucher
              }
              type="number"
            />
          </Grid>
          {/* type of send */}
          <Grid item xs={12}>
            <SendTypeInput
              sendType={formik.values.type_send}
              onChangeHandler={formik.handleChange}
              error={
                formik.touched.type_send && Boolean(formik.errors.type_send)
              }
              helperText={formik.touched.type_send && formik.errors.type_send}
            />
          </Grid>
          {/* reminder */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Reminders (Number of Days):
            </InputLabel>
            <Input
              id="reminder"
              name="reminder"
              label="Enter Reminder"
              value={formik.values.reminder}
              onChange={formik.handleChange}
              error={formik.touched.reminder && Boolean(formik.errors.reminder)}
              helperText={formik.touched.reminder && formik.errors.reminder}
              type="number"
            />
          </Grid>
        </Grid>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          className={classes.submit}
          type="submit"
        >
          {company ? "Update Merchant" : "Add Merchant"}
        </Button>
      </form>
    </div>
  );
};

export default Company;
