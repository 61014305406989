import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { primaryColor } from "assets/jss/material-dashboard-react.js";

export default function Clients({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  showClient,
  deleteClient,
  lockClient,
}) {
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete this Client ? </h5>
          <div>
            <Button color="danger" size="lg" onClick={() => deleteClient(id)}>
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those Clients ? </h5>
          <div>
            <Button
              color="danger"
              size="lg"
              onClick={() => deleteClient(selected)}
            >
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const handleLock = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="info"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to block/unblock this Client ? </h5>
          <div>
            <Button color="primary" size="lg" onClick={() => lockClient(id)}>
              Lock
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="info"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to block/unblock those Clients ? </h5>
          <div>
            <Button
              color="primary"
              size="lg"
              onClick={() => lockClient(selected)}
            >
              Lock
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button color="danger" aria-label="delete" onClick={() => handleDelete()}>
        Delete Selected
      </Button>
      <Button
        color="primary"
        aria-label="un/block"
        onClick={() => handleLock()}
      >
        Lock/UnLock selected
      </Button>
    </div>
  );

  const searchByOptions = [
    {
      value: "user_name",
      label: "Name",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "user_phone",
      label: "Phone",
    },
  ];

  const headCells = [
    {
      id: "user_name",
      sortable: true,
      label: "Name",
    },
    { id: "email", sortable: true, label: "Email" },
    { id: "user_phone", sortable: true, label: "Phone" },
    { id: "image", sortable: false, label: "Image" },
    { id: "status", sortable: true, label: "Status" },
    {
      id: "created_at",
      sortable: true,
      label: "Created at",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  style={{
                    color: primaryColor[1],
                    "&:checked": {
                      color: primaryColor[1],
                    },
                  }}
                  color="default"
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showClient(row.id)}
              >
                {row.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showClient(row.id)}
              >
                {row.email}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.phone ? (
                  <a href={`tel:${row.phone}`}>
                    <span>{row.phone}</span>
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showClient(row.id)}
              >
                <Avatar
                  alt={row.name}
                  src={row.image}
                  style={{ margin: "0 auto" }}
                >
                  <span style={{ textTransform: "uppercase" }}>
                    {row.name[0]}
                  </span>
                </Avatar>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showClient(row.id)}
              >
                {row.is_active !== "active" ? (
                  <Tooltip title="Client's email not activated">
                    <i className="fas fa-user-times"></i>
                  </Tooltip>
                ) : (
                  <Tooltip title="Client's email activated">
                    <i className="fas fa-user-check"></i>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showClient(row.id)}
              >
                {row.created_at}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Delete Client">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete([row.id])}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="blocked/UnBlocked Client">
                    <IconButton
                      aria-label="lock"
                      onClick={() => handleLock([row.id])}
                    >
                      <LockIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Open Client's details">
                    <IconButton
                      aria-label="show"
                      onClick={() => showClient(row.id)}
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={8} align="center">
            No Users CLients
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={null}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
