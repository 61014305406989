import * as api from "../api";
import * as actionsType from "./actionsType";

export const getSubCategories = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_SUB_CATEGORIES,
      payload: null,
    });
    const { data } = await api.getSubCategories(formData);
    dispatch({ type: actionsType.GET_SUB_CATEGORIES, payload: data });
    dispatch(getAllCategories(history));
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteSubCategory = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteSubCategory({ ids: idsArray });
    dispatch(getSubCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Sub Category deleted Successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getAllCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_ALL_CATEGORIES,
      payload: null,
    });
    const { data } = await api.getAllCategories();

    dispatch({
      type: actionsType.GET_ALL_CATEGORIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

// export const getAllPriorites = (history) => async (dispatch) => {
//   try {
//     dispatch({
//       type: actionsType.GET_ALL_PRIORITIES,
//       payload: null
//     });
//     const { data } = await api.getAllPriorities();

//     dispatch({
//       type: actionsType.GET_ALL_PRIORITIES,
//       payload: data
//     });
//   } catch (error) {
//     let errorMessage =
//       error.response.status + " " + error.response.data.message;
//     dispatch({
//       type: actionsType.SET_MESSAGE,
//       payload: { type: "danger", text: errorMessage }
//     });
//     if (error.response.status === 401 || error.response.status === 403) {
//       history.push("/");
//     }
//   }
// };

export const createSubCategory = (newData, formData, history) => async (
  dispatch
) => {
  try {
    await api.createSubCategory(newData);
    dispatch(getSubCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Sub Category has been created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateSubCategory = (newData, formData, history) => async (
  dispatch
) => {
  try {
    await api.updateSubCategory(newData);
    dispatch(getSubCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Sub Category has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
