import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/Shared/CustomInput/CustomInput.js";
import Button from "components/Shared/CustomButtons/Button.js";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: "1 1 100%"
  },
  controlsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block"
    }
  },
  searchWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  searchInput: {
    margin: "0 10px",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      width: "70%"
    }
  },
  searchInputContainer: {
    margin: "0 10px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%"
    }
  },
  searchByContainer: {
    margin: "0 10px",
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "90%"
    }
  },
  searchByInput: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  clearButton: {
    width: "10%",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "80%",
      margin: "10px auto",
      marginBottom: "20px"
    }
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDelete,
    setSearchHandler,
    searchHandler,
    searchValue,
    clearSearchHandler,
    openCreateModal,
    selectedSearchBy,
    handleSearchByChange
  } = props;

  const handleSearchBy = (event) => {
    handleSearchByChange(event.target.value);
  };

  return (
    <Toolbar className={classes.root}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Tooltip title="Delete selected">
            <IconButton aria-label="delete" onClick={() => handleDelete()}>
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div className={classes.controlsWrapper}>
          <div>
            <Button color="primary" onClick={openCreateModal}>
              Add Branch User
            </Button>
          </div>
          <div className={classes.searchWrapper}>
            <div className={classes.searchInputContainer}>
              <CustomInput
                formControlProps={{
                  className: classes.search
                }}
                inputProps={{
                  placeholder: "Search",
                  inputProps: {
                    "aria-label": "Search"
                  },
                  onChange: setSearchHandler,
                  value: searchValue
                }}
              />
              <Button
                color="transparentBlack"
                aria-label="edit"
                justIcon
                round
                onClick={searchHandler}
              >
                <Search />
              </Button>
            </div>
            <div className={classes.searchByContainer}>
              <InputLabel shrink htmlFor="search-by">
                Search By
              </InputLabel>
              <NativeSelect
                className={classes.searchByInput}
                value={selectedSearchBy}
                onChange={handleSearchBy}
                inputProps={{
                  name: "search-by",
                  id: "search-by"
                }}
              >
                <option value="user_name">Name</option>
                <option value="email">Email</option>
                {/* <option value="status">status</option>
              <option value="created_at">created at</option> */}
              </NativeSelect>
            </div>
            <Button
              className={classes.clearButton}
              color="info"
              onClick={clearSearchHandler}
            >
              clear
            </Button>
          </div>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default EnhancedTableToolbar;
