/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// core components
import AdminNavbarLinks from "components/Shared/Navbars/AdminNavbarLinks.js";
import SidebarLink from "./SidebarLink";
import { userLogout } from "store/actions/auth";
import { isMobile } from "react-device-detect";
import bgImage from "assets/img/bgImg.jpg";
import styles from "./sidebarStyle";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  const { color, logo, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => (
        <SidebarLink
          route={prop}
          color={color}
          key={key}
          activeRoute={activeRoute}
          clickHandler={isMobile ? props.handleDrawerToggle : () => {}}
        />
      ))}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <NavLink to="/" className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </NavLink>
    </div>
  );

  const handleLogout = () => {
    dispatch(userLogout(history));
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            {brand}
            <div className={classes.sidebarWrapperContainer}>
              <div className={classes.sidebarWrapper}>
                {links}
                <div style={{ marginTop: "40px", marginButton: "20px" }}>
                  <ListItem
                    button
                    className={classes.itemLink}
                    onClick={handleLogout}
                  >
                    <ListItemText
                      primary="Logout"
                      className={classes.itemText}
                      disableTypography={true}
                    />
                  </ListItem>
                </div>
              </div>
              <div style={{ zIndex: 4 }}>
                <span style={{ color: "#fff" }}>
                  <strong style={{ display: "block" }}>
                    Contact support:{" "}
                  </strong>
                  <a
                    href="tel:+201063412170"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    +201063412170
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            {brand}
            <div className={classes.sidebarWrapperContainer}>
              <div className={classes.sidebarWrapper}>{links}</div>
              <div style={{ zIndex: 4 }}>
                <span style={{ color: "#fff" }}>
                  <strong style={{ display: "block" }}>
                    Contact support:{" "}
                  </strong>
                  <a
                    href="tel:+201063412170"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    +201063412170
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
