import * as actionsType from "../actions/actionsType";

const branchesReducer = (
  state = {
    branches: null,
    branch: null,
    companies: null,
    days: null,
    subcategories: null,
    archived: null,
    collected: null,
    collectableIds: null,
    notCollected: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case actionsType.GET_BRANCH:
      return {
        ...state,
        branch: action.payload,
      };
    case actionsType.GET_BRANCH_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionsType.GET_BRANCH_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload,
      };
    case actionsType.GET_BRANCH_DAYS:
      return {
        ...state,
        days: action.payload,
      };
    case actionsType.SET_BRANCH_ARRAY:
      return {
        ...state,
        collectableIds: action.payload,
      };
    case actionsType.GET_BRANCH_ARCHIVED:
      return {
        ...state,
        archived: action.payload,
      };
    case actionsType.GET_BRANCH_COLLECTED:
      return {
        ...state,
        collected: action.payload,
      };
    case actionsType.GET_BRANCH_NOT_COLLECTED:
      return {
        ...state,
        notCollected: action.payload,
      };
    default:
      return state;
  }
};

export default branchesReducer;
