import React from "react";
import useStyles from "./CollectableStyle";

const Branch = ({ collectable }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.merchant}>
        <span className={classes.label}>Merchant:</span>
        <span className={classes.content}>{collectable.company}</span>
      </div>
      <div className={classes.merchant}>
        <span className={classes.label}>Branch:</span>
        <span className={classes.content}>{collectable.branch}</span>
      </div>
      <div className={classes.merchant}>
        <span className={classes.label}>Type:</span>
        <span className={classes.content}>{collectable.type_of_send}</span>
      </div>
      <div>
        <span className={classes.label}>Voucher Code:</span>
        <span className={classes.content}>{collectable.voucher_code}</span>
      </div>
      <div>
        <span className={classes.label}>Voucher used:</span>
        <span className={classes.content}>
          {collectable.voucher_is_used === 1 ? "used" : "not used"}
        </span>
      </div>
    </div>
  );
};

export default Branch;
