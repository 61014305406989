import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import Table from "components/Admin/Vouchers/Voucher/Voucher";
import { getVoucher, deleteSingleVoucher } from "store/actions/vouchers";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
});

const useStyles = makeStyles(styles);

const VoucherContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const voucherData = useSelector((state) => state.vouchers.voucher);
  console.log(voucherData);
  const [page, setPage] = useState(voucherData ? voucherData.pag.page : 1);
  const [amount, setAmount] = useState(
    voucherData ? voucherData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    voucherData ? voucherData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    voucherData ? voucherData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    voucherData ? voucherData.pag.search_by : "code"
  );

  useEffect(() => {
    dispatch(
      getVoucher(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          company_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (vid) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
      company_id: id,
    };
    dispatch(deleteSingleVoucher(vid, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Voucher Table</h4>
        </CardHeader>
        <CardBody>
          {voucherData ? (
            <Table
              rows={voucherData.data}
              totalRows={voucherData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              deleteHandler={deleteHandler}
            />
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default VoucherContainer;
