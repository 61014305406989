import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import home from "./home";
import vouchers from "./vouchers";
import collectables from "./collectables";
import companies from "./companies";
import branches from "./branches";
import categories from "./categories";
import subCategories from "./subCategories";
import mobileUpdate from "./mobileUpdate";
import brands from "./brands";
import support from "./support";
export default combineReducers({
  auth,
  users,
  home,
  collectables,
  support,
  vouchers,
  branches,
  companies,
  categories,
  subCategories,
  brands,
  mobileUpdate,
});
