import {
  defaultFont,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";

const authStyle = {
  main: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryColor[1],
    paddingTop: "4rem",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
  },
  title: {
    ...defaultFont,
    margin: "0",
    fontWeight: "900",
    color: primaryColor[1],
  },
  avatar: {
    backgroundColor: "transparent",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "3rem",
  },
  submit: {
    margin: "2rem 0 1rem!important",
  },
};

export default authStyle;
