import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import CustomMessage from "components/Shared/CustomMessage/CustomMessage";
import Admin from "layouts/Admin/Admin";
import Home from "./views/Home/Home";
import Login from "./views/Auth/Login";
import ForgotPass from "./views/Auth/ForgotPass";
import ResetPass from "./views/Auth/ResetPass";
import SetPass from "./views/Auth/SetPass";
import Awareness from "./views/Main/Awareness/Awareness";
import Copyright from "./views/Main/Copyright/Copyright";
import Recycles from "./views/Main/Recycles/Recycles";
import Policy from "./views/Main/Policy/Policy";
import { getMe } from "store/actions/auth";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useSelector((state) => state.auth.authData);
  if (!authData) {
    dispatch(getMe(history));
  }
  const { email, token, content } = queryString.parse(history.location.search);

  return (
    <React.Fragment>
      <CustomMessage />
      <Switch>
        <Route
          exact
          path="/"
          component={() =>
            email && token && !localStorage.getItem("token") ? (
              content === "reset-password" ? (
                <Redirect
                  to={`/reset-password?email=${email}&token=${token}`}
                />
              ) : content === "setup-password" ? (
                <Redirect to={`/set-password?email=${email}&token=${token}`} />
              ) : (
                <Home />
              )
            ) : (
              <Home />
            )
          }
        />
        <Route
          path="/login"
          component={() =>
            localStorage.getItem("token") ? <Redirect to="/" /> : <Login />
          }
        />
        <Route
          path="/forgot-password"
          component={() =>
            localStorage.getItem("token") ? <Redirect to="/" /> : <ForgotPass />
          }
        />
        <Route
          path="/reset-password"
          component={(props) =>
            localStorage.getItem("token") ? (
              <Redirect to="/" />
            ) : (
              <ResetPass {...props} />
            )
          }
        />
        <Route
          path="/set-password"
          component={(props) =>
            localStorage.getItem("token") ? (
              <Redirect to="/" />
            ) : (
              <SetPass {...props} />
            )
          }
        />
        <Route path="/awareness" component={Awareness} />
        <Route path="/copyright" component={Copyright} />
        <Route path="/recycles" component={Recycles} />
        <Route path="/policy" component={Policy} />
        <Route
          path="/admin"
          component={() =>
            localStorage.getItem("token") ? <Admin /> : <Redirect to="/" />
          }
        />
      </Switch>
    </React.Fragment>
  );
};

export default App;
