import React from "react";
import { Grid, InputLabel, Switch } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./MobileStyle";
import FieldSelect from "./FieldSelect";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  check_for: yup.string("Enter Check for").required("Check for required"),
  notify: yup.string("Enter Notify").required("Notify required"),
  app_store_id: yup
    .string("Enter App Store id")
    .required("App Store id required"),
  comparing: yup.string("Enter Comparing").required("Comparing required"),
  min_os_required: yup
    .string("Enter Min Os Required IOS")
    .required("Min Os Required IOS required"),
  min_os_required_android: yup
    .string("Enter Min os Required Android")
    .required("Min os Required Android required"),
  version: yup.string("Enter Version IOS").required("Version IOS required"),
  version_android: yup
    .string("Enter Version Android")
    .required("Version Android required"),
});

const MobileUpdateComponent = ({ data, handleUpdate }) => {
  const formik = useFormik({
    initialValues: {
      check_for: data?.check_for,
      notify: data?.notify,
      app_store_id: data?.app_store_id,
      comparing: data?.comparing,
      min_os_required: data?.min_os_required,
      min_os_required_android: data?.min_os_required_android,
      version: data?.version,
      version_android: data?.version_android,
      force: data?.force,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const [formData, setFormData] = useState(initialState);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values) => {
    handleUpdate(values);
  };

  const handleChangeSwitch = () => {
    let change;
    if (formik.values.force === 0) {
      change = {
        target: { name: "force", value: 1 },
      };
    } else {
      change = {
        target: { name: "force", value: 0 },
      };
    }

    formik.handleChange(change);
  };

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}
          {/* check for */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Check For:</InputLabel>
            <FieldSelect
              onChangeHandler={formik.handleChange}
              selectedValue={formik.values.check_for}
              fieldName="check_for"
              fieldList={["manually", "automatically"]}
            />
          </Grid>
          {/* notify */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Notify:</InputLabel>
            <FieldSelect
              onChangeHandler={formik.handleChange}
              selectedValue={formik.values.notify}
              fieldName="notify"
              fieldList={["always", "never", "once", "twice", "thrice"]}
            />
          </Grid>
          {/* app store id */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>App Store ID:</InputLabel>
            <Input
              id="app_store_id"
              name="app_store_id"
              label="Enter App Store ID"
              value={formik.values.app_store_id}
              onChange={formik.handleChange}
              error={
                formik.touched.app_store_id &&
                Boolean(formik.errors.app_store_id)
              }
              helperText={
                formik.touched.app_store_id && formik.errors.app_store_id
              }
              type="text"
            />
          </Grid>
          {/* comparing */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Comparing:</InputLabel>
            <FieldSelect
              onChangeHandler={formik.handleChange}
              selectedValue={formik.values.comparing}
              fieldName="comparing"
              fieldList={["major-versions", "minor-versions", "patch-versions"]}
            />
          </Grid>
          {/* min-os-ios */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Min OS Required (IOS):
            </InputLabel>
            <Input
              id="min_os_required"
              name="min_os_required"
              label="Enter Min OS Required IOS"
              value={formik.values.min_os_required}
              onChange={formik.handleChange}
              error={
                formik.touched.min_os_required &&
                Boolean(formik.errors.min_os_required)
              }
              helperText={
                formik.touched.min_os_required && formik.errors.min_os_required
              }
              type="text"
            />
          </Grid>
          {/* min-os-android */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Min OS Required (Android)
            </InputLabel>
            <Input
              id="min_os_required_android"
              name="min_os_required_android"
              label="Enter Min OS Required Android"
              value={formik.values.min_os_required_android}
              onChange={formik.handleChange}
              error={
                formik.touched.min_os_required_android &&
                Boolean(formik.errors.min_os_required_android)
              }
              helperText={
                formik.touched.min_os_required_android &&
                formik.errors.min_os_required_android
              }
              type="text"
            />
          </Grid>
          {/* version-ios */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Version (IOS):</InputLabel>
            <Input
              id="version"
              name="version"
              label="Enter version IOS"
              value={formik.values.version}
              onChange={formik.handleChange}
              error={formik.touched.version && Boolean(formik.errors.version)}
              helperText={formik.touched.version && formik.errors.version}
              type="text"
            />
          </Grid>
          {/* version-android */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>
              Version (Android):
            </InputLabel>
            <Input
              id="version_android"
              name="version_android"
              label="Enter version Android"
              value={formik.values.version_android}
              onChange={formik.handleChange}
              error={
                formik.touched.version_android &&
                Boolean(formik.errors.version_android)
              }
              helperText={
                formik.touched.version_android && formik.errors.version_android
              }
              type="text"
            />
          </Grid>
          {/* force */}
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.switchContainer}
              control={
                <Switch
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  checked={formik.values.force === 1}
                  onChange={handleChangeSwitch}
                  name="force"
                />
              }
              label={
                <InputLabel
                  className={classes.label}
                  style={{ margin: "auto" }}
                >
                  Force Update
                </InputLabel>
              }
            />
          </Grid>
        </Grid>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          className={classes.submit}
          type="submit"
        >
          Update Mobile Version
        </Button>
      </form>
    </div>
  );
};

export default MobileUpdateComponent;
