import * as api from "../api";
import * as actionsType from "./actionsType";

export const login = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.login(formData);
    dispatch({ type: actionsType.AUTH, payload: data.data });
    localStorage.setItem("token", data.access_token);
    const now = new Date().getTime();
    const exp = now + data.expires_in * 1000;
    localStorage.setItem("exp", exp);
    history.push("/admin");
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Login Successfully" }
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage }
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const userLogout = (history) => async (dispatch) => {
  try {
    // const { data } = await api.logout();
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "logout successfully" }
    });
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
    dispatch({ type: actionsType.LOGOUT });
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
    console.log(error);
  }
};

export const forgotPass = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.forgetPassword(formData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: data.message }
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage }
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const resetPass = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.resetPassword(formData);
    dispatch({ type: actionsType.AUTH, payload: data.data });
    localStorage.setItem("token", data.access_token);
    const now = new Date().getTime();
    const exp = now + data.expires_in * 1000;
    localStorage.setItem("exp", exp);
    history.push("/");
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Login Successfully" }
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage }
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/admin/dashboard");
    }
  }
};
export const setPass = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.setPassword(formData);
    history.push("/login");
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "password set Successfully" }
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage }
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getMe = (history) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const exp = localStorage.getItem("exp");
    const now = new Date().getTime();
    if (token && exp) {
      if (exp < now) {
        localStorage.removeItem("token");
        localStorage.removeItem("exp");
        history.push("/");
      } else if (exp - now <= 86400000) {
        const { data } = await api.refreshToken();
        dispatch({ type: actionsType.AUTH, payload: data });
        localStorage.setItem("token", data.access_token);
        const newExp = now + data.expires_in * 1000;
        localStorage.setItem("exp", newExp);
        history.push("/admin");
        dispatch({
          type: actionsType.SET_MESSAGE,
          payload: { type: "success", text: "Login Successfully" }
        });
      } else {
        const { data } = await api.me();
        dispatch({ type: actionsType.AUTH, payload: data });
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("exp");
    }
  } catch (error) {
    // alert(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage }
    });
    localStorage.removeItem("token");
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
