import * as api from "../api";
import * as actionsType from "./actionsType";

export const searchVoucherCode = (code, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.VOUCHER_IS_SEARCHING,
      payload: { loading: true, message: null },
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_MANAGER_VOUCHER,
      payload: null,
    });
    const { data } = await api.managerVoucherSeacrch(code);
    dispatch({
      type: actionsType.SET_MANAGER_VOUCHER,
      payload: data.data,
    });
    dispatch({
      type: actionsType.VOUCHER_IS_SEARCHING,
      payload: { loading: false, message: null },
    });
  } catch (error) {
    if (error.response.status === 422) {
      dispatch({
        type: actionsType.VOUCHER_IS_SEARCHING,
        payload: { loading: false, message: "No voucher Found" },
      });
    } else {
      dispatch({
        type: actionsType.VOUCHER_IS_SEARCHING,
        payload: { loading: false, message: null },
      });
      let errorMessage =
        error.response.status + " " + error.response.data.message;
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "danger", text: errorMessage },
      });
      if (error.response.status === 401 || error.response.status === 403) {
        history.push("/");
      }
    }
  }
};

export const setVoucherUsed = (code, history) => async (dispatch) => {
  try {
    await api.managerVoucherIsUsed(code);
    dispatch({
      type: actionsType.SET_MANAGER_VOUCHER,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "voucher used successfully" },
    });
  } catch (error) {
    let errorMessage =
      error?.response?.status + " " + error?.response?.data?.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getVouchers = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHERS,
      payload: null,
    });
    const { data } = await api.getVouchers(formData);
    dispatch({ type: actionsType.GET_VOUCHERS, payload: data });
    dispatch(getCompanies(history));
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getVoucher = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHER,
      payload: null,
    });
    const { data } = await api.getVoucher(formData);
    dispatch({
      type: actionsType.GET_VOUCHER,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
export const deleteSingleVoucher = (vid, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteSingleVoucher({ ids: vid });
    dispatch(getVoucher(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "voucher Deleted successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteVoucher = (id, formData, history) => async (dispatch) => {
  try {
    await api.deleteVoucher({ company_id: id });
    dispatch(getVouchers(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Voucher deleted Successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createVoucher = (newData, formData, history) => async (
  dispatch
) => {
  try {
    const formNewData = new FormData();
    formNewData.append("file", newData.file);
    formNewData.append("company_id", newData.company_id);
    await api.createVoucher(formNewData);
    dispatch(getVouchers(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Voucher has been created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHER_COMPANIES,
      payload: null,
    });
    const { data } = await api.getAllCompanies();

    dispatch({
      type: actionsType.GET_VOUCHER_COMPANIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
