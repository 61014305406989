import * as actionsType from "../actions/actionsType";

const companiesReducer = (
  state = {
    companies: null,
    company: null,
    mainCompanies: null,
    companyCollectables: null,
    companyCollectablesIds: null,
    companyNumbers: null
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_COMPANY_NUMBERS:
      return {
        ...state,
        companyNumbers: action.payload
      };
    case actionsType.GET_COMPANY_COLLECTABLES:
      return {
        ...state,
        companyCollectables: action.payload
      };
    case actionsType.SET_COMPANY_COLLECTABLES_ARRAY:
      return {
        ...state,
        companyCollectablesIds: action.payload
      };
    case actionsType.GET_MAIN_COMPANIES:
      return {
        ...state,
        mainCompanies: action.payload
      };
    case actionsType.GET_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case actionsType.GET_COMPANIES:
      return {
        ...state,
        companies: action.payload
      };
    default:
      return state;
  }
};

export default companiesReducer;
