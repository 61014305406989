import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Table from "components/Admin/Collectables/Collectables";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getWaiting,
  getCompanies,
  approveCollectables,
  rejectCollectables,
  bounceCollectable,
  getRejectReason
} from "store/actions/collectables";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  loadingContainer: {
    textAlign: "center"
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important"
  }
};

const useStyles = makeStyles(styles);

const Waiting = () => {
  const selectedCompanyId = parseInt(localStorage.getItem("companyId"), 10);
  const waitingData = useSelector((state) => state.collectables.waiting);
  const companiesData = useSelector((state) => state.collectables.companies);
  const rejectReasonData = useSelector(
    (state) => state.collectables.rejectReason
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(waitingData ? waitingData.pag.page : 1);
  const [amount, setAmount] = useState(
    waitingData ? waitingData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    waitingData ? waitingData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    waitingData ? waitingData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    waitingData ? waitingData.pag.search_by : "model_name"
  );
  const [companyId, setCompanyId] = useState(
    selectedCompanyId ? selectedCompanyId : ""
  );
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (companyId !== "") {
      localStorage.setItem("companyId", companyId);
    } else {
      localStorage.removeItem("companyId");
    }
    dispatch(
      getWaiting(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          company_id: companyId,
          search_by: searchBy
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, companyId, dispatch]);

  useEffect(() => {
    dispatch(getCompanies(history));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRejectReason(history));
  }, [dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  const approveRecycableHandler = (ids) => {
    dispatch(
      approveCollectables(
        ids,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          company_id: companyId,
          search_by: searchBy
        },
        history,
        true
      )
    );
  };
  const rejectRecycableHandler = (ids, reasonId) => {
    dispatch(
      rejectCollectables(
        ids,
        reasonId,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          company_id: companyId,
          search_by: searchBy
        },
        history
      )
    );
  };

  const bounceRecycableHandler = (id) => {
    dispatch(
      bounceCollectable(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Waiting Collectables</h4>
        </CardHeader>
        <CardBody>
          {waitingData ? (
            <Table
              rows={waitingData.data}
              totalRows={waitingData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              showRecycable={showRecycableHandler}
              rejectRecycable={rejectRecycableHandler}
              bounceRecycable={bounceRecycableHandler}
              approveRecycable={approveRecycableHandler}
              isSelectable
              canApprove
              canReject
              showFilter={companiesData ? true : false}
              companies={companiesData}
              setCompany={setCompanyId}
              selectedCompany={companyId}
              rejectReason={rejectReasonData}
              bounceRecycable={bounceRecycableHandler}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
            />
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Waiting;
