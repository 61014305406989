import * as api from "../api";
import * as actionsType from "./actionsType";

export const getBranches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: null,
    });
    const { data } = await api.getBranches(formData);
    dispatch({ type: actionsType.GET_BRANCHES, payload: data });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteBranch = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteBranch({ ids: idsArray });
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: null,
    });
    const { data } = await api.getBranches(formData);
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Branch deleted Successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const resetBranch = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.resetBranch({
      ids: idsArray,
    });
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: null,
    });
    const { data } = await api.getBranches(formData);
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "branch reseted Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const changeStatusBranch = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.changeStatusBranch({
      ids: idsArray,
    });
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: null,
    });
    const { data } = await api.getBranches(formData);
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "status has been changed successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const clearBranch = () => (dispatch) => {
  dispatch({
    type: actionsType.GET_BRANCH,
    payload: null,
  });
};

export const getBranch = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH,
      payload: null,
    });
    dispatch({
      type: actionsType.GET_BRANCH_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.GET_BRANCH_DAYS,
      payload: null,
    });
    const branchRes = await api.getBranch(id);
    dispatch({
      type: actionsType.GET_BRANCH,
      payload: branchRes.data.data,
    });
    const companiesRes = await api.getAllCompanies();
    dispatch({
      type: actionsType.GET_BRANCH_COMPANIES,
      payload: companiesRes.data,
    });
    const BranchDays = await api.getDays();
    dispatch({
      type: actionsType.GET_BRANCH_DAYS,
      payload: BranchDays.data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_COMPANIES,
      payload: null,
    });
    const { data } = await api.getAllCompanies();

    dispatch({
      type: actionsType.GET_BRANCH_COMPANIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getDays = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_DAYS,
      payload: null,
    });
    const { data } = await api.getDays();

    dispatch({
      type: actionsType.GET_BRANCH_DAYS,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getSubCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_SUBCATEGORIES,
      payload: null,
    });
    const { data } = await api.getAllSubCategories();

    dispatch({
      type: actionsType.GET_BRANCH_SUBCATEGORIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createBranch = (newData, history) => async (dispatch) => {
  try {
    await api.createBranch(newData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Branch has been created successfully",
      },
    });
    history.push("/admin/merchants/branches");
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateBranch = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH,
      payload: null,
    });
    await api.updateBranch(newData);
    const branchRes = await api.getBranch(newData.id);
    dispatch({
      type: actionsType.GET_BRANCH,
      payload: branchRes.data.data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "branch has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getBranchArchived = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_ARCHIVED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: [],
    });
    const { data } = await api.getBranchCollectablesArchived(formData);
    let idsArray = [];
    data.data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_BRANCH_ARCHIVED,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getBranchCollected = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_COLLECTED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: [],
    });
    const { data } = await api.getBranchCollectablesCollected(formData);
    let idsArray = [];
    data.data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_BRANCH_COLLECTED,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getBranchNotCollected = (formData, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_NOT_COLLECTED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: [],
    });
    const { data } = await api.getBranchCollectablesNotCollected(formData);
    let idsArray = [];
    data.data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_BRANCH_NOT_COLLECTED,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const exportBranchCollectedPdf = (id, history) => async (dispatch) => {
  try {
    const { data } = await api.branchCollectablesCollectedPdf(id);
    window.open(data, "_blank");
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
