import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Table from "components/Admin/Users/Recyclers/Recyclers";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecyclers,
  createNewRecycler,
  updateRecycler,
  deleteRecycler,
  changeActiveRecycler,
  getAllBranches,
} from "store/actions/users";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
};

const useStyles = makeStyles(styles);

const Recyclers = () => {
  const usersRecyclersData = useSelector((state) => state.users.usersRecyclers);
  const branchesListData = useSelector((state) => state.users.allBranches);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    usersRecyclersData ? usersRecyclersData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    usersRecyclersData ? usersRecyclersData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    usersRecyclersData ? usersRecyclersData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    usersRecyclersData ? usersRecyclersData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    usersRecyclersData ? usersRecyclersData.pag.search_by : "name"
  );
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getRecyclers(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  useEffect(() => {
    dispatch(getAllBranches(history));
  }, []);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteRecycler(id, pag, history));
  };

  const changeStatusHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeActiveRecycler(id, pag, history));
  };

  const createHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
    };
    dispatch(createNewRecycler(newUser, pag, history));
  };

  const updateHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(updateRecycler(newUser, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Recyclers Table</h4>
        </CardHeader>
        <CardBody>
          {usersRecyclersData ? (
            <Table
              rows={usersRecyclersData.data}
              totalRows={usersRecyclersData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              deleteUser={deleteHandler}
              createUser={createHandler}
              updateUser={updateHandler}
              changeStatus={changeStatusHandler}
              branchesList={branchesListData}
            />
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Recyclers;
