import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Manager/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import { getCollectableManager, collect } from "store/actions/collectables";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
};

const useStyles = makeStyles(styles);

const Collectable = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const selectedCollectable = useSelector(
    (state) => state.collectables.collectableManager
  );
  console.log(selectedCollectable);
  useEffect(() => {
    dispatch(getCollectableManager(id, history));
  }, [id, dispatch]);

  const collectHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to collect this Recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="success"
            size="lg"
            onClick={collectRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Collect
          </Button>
          <Button
            color="info"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  const collectRecycable = () => {
    setOpenModal(false);
    dispatch(collect(id, history));
  };
  return (
    <div>
      {selectedCollectable ? (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparentBlack"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ margin: "auto", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Recycable Info</h4>
            </CardHeader>
            <CardBody>
              <div className={classes.controlsContainer}>
                {selectedCollectable.is_collected === 0 && (
                  <Button color="info" onClick={collectHandler}>
                    Collect
                  </Button>
                )}
              </div>
              <RecycableInfo collectable={selectedCollectable} />
            </CardBody>
          </Card>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </div>
      )}
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Collectable;
