import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import useStyles from "./TutorialStyle";
import Tutorial from "./Tutorial";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

function AdminTutorial({ admin, manager }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Provider Info Tabs"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            label="Admins"
            {...a11yProps(0)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Managers"
            {...a11yProps(1)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Recyclers"
            {...a11yProps(2)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {admin.map((value, index) => (
          <Tutorial title={value.title} yid={value.yid} key={index} />
        ))}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {manager.map((value, index) => (
          <Tutorial title={value.title} yid={value.yid} key={index} />
        ))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={{ height: "200px", textAlign: "center" }}>
          <h4>Coming Soon</h4>
        </div>
      </TabPanel>
    </div>
  );
}

export default AdminTutorial;
