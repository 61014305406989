import {
  defaultFont,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: primaryColor[1],
    color: whiteColor,
  },
  content: {
    flex: "3",
    textAlign: "center",
    marginBottom: "20px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
  },
  title: {
    ...defaultFont,
    margin: "0",
    fontWeight: "bold",
    color: whiteColor,
  },
}));
