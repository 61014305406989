import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import AdminTutorial from "components/Shared/Tutorial/AdminTutorial";
import ManagerTutorial from "components/Shared/Tutorial/ManagerTutorial";

const styles = {
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardSubtitle: {
    color: "#191919",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#191919",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const adminTutorials = [
  {
    title: "How to register",
    yid: "vg7oWonrrxY",
  },
  {
    title: "How to collect recycable",
    yid: "l9BhV9dPiPk",
  },
  {
    title: "Archived or Collected Recycable",
    yid: "",
  },
  {
    title: "How to create a new Agent user",
    yid: "",
  },
];
const managerTutorials = [
  {
    title: "How to register",
    yid: "vg7oWonrrxY",
  },
  {
    title: "How to collect recycable",
    yid: "l9BhV9dPiPk",
  },
  {
    title: "Archived or Collected Recycable",
    yid: "43tB1myzvu0",
  },
  {
    title: "How to create a new Agent user",
    yid: "9gBYgq2utvw",
  },
];

const Tutorial = () => {
  const classes = useStyles();
  const [userRole, setUserRole] = useState("");
  const authData = useSelector((state) => state.auth.authData);

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitle}>KnowledgeBase Page</h4>
        </CardHeader>
        <CardBody>
          <div>
            <h4 className={classes.cardSubTitle}>
              this is the Knowlegde base page where you can find all videos
              tutorial{" "}
            </h4>
          </div>
          {authData && (
            <div>
              {authData.role === "admin" || authData.role === "sub_admin" ? (
                <AdminTutorial
                  admin={adminTutorials}
                  manager={managerTutorials}
                />
              ) : authData.role === "manager" || authData.role === "agent" ? (
                <ManagerTutorial manager={managerTutorials} />
              ) : null}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Tutorial;
