import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Table from "components/Manager/Collectables/Archived/CollectablesTable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import { getArchivedManager, exportArchive } from "store/actions/collectables";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  loadingContainer: {
    textAlign: "center"
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important"
  }
};

const useStyles = makeStyles(styles);

const Archived = () => {
  const archivedData = useSelector((state) => state.collectables.archived);
  const authData = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();
  const [page, setPage] = useState(archivedData ? archivedData.pag.page : 1);
  const [amount, setAmount] = useState(
    archivedData ? archivedData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedData ? archivedData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedData ? archivedData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    archivedData ? archivedData.pag.search_by : "model_name"
  );
  const [startDate, setStartDate] = useState(
    archivedData ? archivedData.pag.from : null
  );
  const [endDate, setEndDate] = useState(
    archivedData ? archivedData.pag.to : null
  );

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getArchivedManager(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          date_from: startDate,
          date_to: endDate
        },
        history
      )
    );
  }, [
    page,
    amount,
    orderField,
    orderType,
    searchValue,
    startDate,
    endDate,
    dispatch
  ]);

  const ExportHandler = () => {
    dispatch(
      exportArchive(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          date_from: startDate,
          date_to: endDate
        },
        history
      )
    );
  };

  const changeStartDate = (date) => {
    if (date) {
      setStartDate(new Date(date).getTime() / 1000);
    } else {
      setStartDate(null);
    }
  };
  const changeEndDate = (date) => {
    if (date) {
      setEndDate(new Date(date).getTime() / 1000);
    } else {
      setEndDate(null);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Archived Collectables</h4>
        </CardHeader>
        <CardBody>
          {archivedData ? (
            <Table
              rows={archivedData.data}
              totalRows={archivedData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              showFilter={authData.role === "manager" ? true : false}
              startDate={startDate ? new Date(startDate * 1000) : ""}
              endDate={endDate ? new Date(endDate * 1000) : ""}
              changeStartDate={changeStartDate}
              changeEndDate={changeEndDate}
              handleExport={authData.role === "manager" ? ExportHandler : null}
            />
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Archived;
