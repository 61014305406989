import React from "react";
import Tutorial from "./Tutorial";
function ManagerTutorial({ manager }) {
  return (
    <div>
      {manager.map((value, index) => (
        <Tutorial title={value.title} yid={value.yid} key={index} />
      ))}
    </div>
  );
}

export default ManagerTutorial;
