import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import DatePicker from "components/Admin/Branches/DatePicker";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Table from "components/Admin/Collectables/Collectables";

import { useDispatch, useSelector } from "react-redux";
import { getBranchArchived, getSubCategories } from "store/actions/branches";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignsItems: "center",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      display: "block",
    },
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
  datePickerContainer: {
    margin: "20px 5px",
    flex: 1,
  },
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "90%",
      display: "block",
    },
  },
  filterInputContainer: {
    marginRight: "10px",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%",
    },
  },
  filterInput: {
    width: "100%",
    margin: "0 auto",
  },
  filterButton: {
    flex: 1,
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "80%",
      margin: "10px auto",
      marginBottom: "20px",
    },
  },
});

const useStyles = makeStyles(styles);

const BranchArchived = () => {
  const { id } = useParams();
  const { archived, subcategories } = useSelector((state) => state.branches);
  const dispatch = useDispatch();
  const [page, setPage] = useState(archived ? archived.pag.page : 1);
  const [amount, setAmount] = useState(archived ? archived.pag.amount : 10);
  const [orderField, setOrderField] = useState(
    archived ? archived.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archived ? archived.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    archived ? archived.pag.search_by : "model_name"
  );
  const [startDate, setStartDate] = useState(
    archived ? (archived.pag.date_from ? archived.pag.date_from : null) : null
  );
  const [endDate, setEndDate] = useState(
    archived ? (archived.pag.date_to ? archived.pag.date_to : null) : null
  );
  const [subcategory, setSubcategory] = useState(
    archived ? archived.pag.subcategory_name : ""
  );

  useEffect(() => {
    dispatch(getSubCategories(history));
  }, [dispatch]);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getBranchArchived(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          id: id,
          subcategory_name: subcategory,
          from: new Date(startDate).getTime() / 1000,
          to: new Date(endDate).getTime() / 1000,
        },
        history
      )
    );
  }, [
    id,
    page,
    amount,
    orderField,
    orderType,
    searchValue,
    subcategory,
    startDate,
    endDate,
    dispatch,
  ]);

  const showRecycableHandler = (id) => {
    history.push("/admin/merchants/branches/recycable/" + id);
  };

  const changeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    } else {
      setStartDate(null);
    }
  };
  const changeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    } else {
      setEndDate(null);
    }
  };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparentBlack"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ margin: "auto", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Branch's Archived Recycables
            </h4>
          </CardHeader>
          <CardBody>
            {archived ? (
              <div>
                <div className={classes.controlsContainer}>
                  <div className={classes.datePickerContainer}>
                    <InputLabel shrink htmlFor="filter-company">
                      Filter By Date
                    </InputLabel>
                    <DatePicker
                      startDate={startDate}
                      endDate={endDate}
                      changeStartDate={changeStartDate}
                      changeEndDate={changeEndDate}
                    />
                  </div>
                  <div className={classes.filterContainer}>
                    <div className={classes.filterInputContainer}>
                      <InputLabel shrink htmlFor="filter-input">
                        Filter by Sub-Categories
                      </InputLabel>
                      <NativeSelect
                        value={subcategory}
                        className={classes.filterInput}
                        onChange={(e) => setSubcategory(e.target.value)}
                        inputProps={{ id: "filter-input" }}
                      >
                        <option value="">None</option>
                        {subcategories.map((option, index) => (
                          <option key={index} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </NativeSelect>
                    </div>
                    <Button
                      className={classes.filterButton}
                      color="info"
                      aria-label="edit"
                      justIcon
                      round
                      onClick={() => setSubcategory("")}
                    >
                      reset
                    </Button>
                  </div>
                </div>
                <Table
                  rows={archived.data}
                  totalRows={archived.pag.total}
                  rowsPerPage={amount}
                  setRowsPerPage={setAmount}
                  order={orderType}
                  setOrder={setOrderType}
                  orderBy={orderField}
                  setOrderBy={setOrderField}
                  page={page - 1}
                  setPage={setPage}
                  showRecycable={showRecycableHandler}
                  searchHandler={setSearchValue}
                  searchValue={searchValue}
                  selectedSearchBy={searchBy}
                  handleSearchByChange={setSearchBy}
                />
              </div>
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress className={classes.loading} />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BranchArchived;
