import {
  defaultFont,
  primaryColor,
  whiteColor
} from "assets/jss/material-dashboard-react.js";

const companyStyle = {
  form: {
    margin: "10px"
  },
  label: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: primaryColor[1] + "!important"
  },
  labelError: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: "red"
  },
  selectInput: {
    width: "100%"
  },
  customCheckbox: {
    color: primaryColor[1],
    "&:checked": {
      color: primaryColor[1]
    }
  },
  customRadio: {
    color: primaryColor[1],
    "&$checked": {
      color: primaryColor[1]
    }
  },
  checked: {},
  chip: {
    backgroundColor: primaryColor[1],
    color: "white"
  }
};

export default companyStyle;
