import React from "react";
import { Grid, InputLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import CompanyInput from "./CompanyInput";
import WorkingDays from "./WorkingDaysInput";
import BranchMap from "./BranchMap";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./BranchStyle";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  name: yup.string("Enter Name").required("Name is required"),
  name_ar: yup.string("Enter Name Arabic").required("Name Arabic is required"),
  address: yup.string("Enter Address").required("Address is required"),
  address_ar: yup
    .string("Enter Address Arabic")
    .required("Address Arabic is required"),
  phone_number: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8)
    .required("A phone number is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter Description")
    .required("Description Arabic is required"),
  capacity: yup
    .number()
    .typeError("Please enter a valid number")
    .positive("cap can't be equal or less then zero")
    .integer("cap can't include a decimal point")
    .required("cap is required"),
  company_id: yup
    .number("choose one of the company id")
    .required("Company is required"),
  lat: yup.number().required("Latitude is required"),
  lng: yup.number().required("Longitude is required"),
  days: yup
    .array()
    .min(1, "Please choose at least one day")
    .required("Please choose at least one day"),
});

const Branch = ({
  branch,
  companiesList,
  daysList,
  isCreate,
  handleCreate,
  handleUpdate,
}) => {
  const formik = useFormik({
    initialValues: {
      name: branch ? branch.name : "",
      name_ar: branch ? branch.name_ar : "",
      address: branch ? branch.address : "",
      address_ar: branch ? branch.address_ar : "",
      phone_number: branch ? branch.phone_number : "",
      description: branch ? branch.description : "",
      description_ar: branch ? branch.description_ar : "",
      capacity: branch ? branch.capacity : "",
      lat: branch ? branch.lat : "30.033333",
      lng: branch ? branch.lng : "31.233334",
      days: branch ? branch.days_ids : [],
      all_days: branch ? branch.all_days : false,
      company_id: branch ? branch.company_id : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const [formData, setFormData] = useState(initialState);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values) => {
    if (isCreate === true) {
      create(values);
    } else {
      update(values);
    }
  };
  const create = (values) => {
    handleCreate(values);
  };
  const update = (values) => {
    handleUpdate(values);
  };
  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}
          {/* name */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name:</InputLabel>
            <Input
              id="name"
              name="name"
              label="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              type="text"
            />
          </Grid>
          {/* name_ar */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name Arabic:</InputLabel>
            <Input
              id="name_ar"
              name="name_ar"
              label="Enter Name Arabic"
              value={formik.values.name_ar}
              onChange={formik.handleChange}
              error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
              helperText={formik.touched.name_ar && formik.errors.name_ar}
              type="text"
            />
          </Grid>
          {/* address */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address English:</InputLabel>
            <Input
              id="address"
              name="address"
              label="Enter Address English"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              type="text"
            />
          </Grid>
          {/* address_ar */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address Arabic:</InputLabel>
            <Input
              id="address_ar"
              name="address_ar"
              label="Enter Address Arabic"
              value={formik.values.address_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.address_ar && Boolean(formik.errors.address_ar)
              }
              helperText={formik.touched.address_ar && formik.errors.address_ar}
              type="text"
            />
          </Grid>
          {/* phone */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Phone Number:</InputLabel>
            <Input
              id="phone_number"
              name="phone_number"
              label="Enter Your phone number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
              type="text"
            />
          </Grid>
          {/* Description English */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description English:
            </InputLabel>
            <Input
              id="description"
              name="description"
              label="Enter Description"
              multiline
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              type="text"
            />
          </Grid>
          {/* Description Arabic */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description Arabic:
            </InputLabel>
            <Input
              id="description_ar"
              name="description_ar"
              label="Enter Description Arabic"
              multiline
              value={formik.values.description_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.description_ar &&
                Boolean(formik.errors.description_ar)
              }
              helperText={
                formik.touched.description_ar && formik.errors.description_ar
              }
              type="text"
            />
          </Grid>
          {/* Capacity Number of Collectables */}
          <Grid item xs={12} sm={4}>
            <InputLabel className={classes.label}>
              Capacity Number of Collectables:
            </InputLabel>
            <Input
              id="capacity"
              name="capacity"
              label="Enter Capacity number"
              value={formik.values.capacity}
              onChange={formik.handleChange}
              error={formik.touched.capacity && Boolean(formik.errors.capacity)}
              helperText={formik.touched.capacity && formik.errors.capacity}
              type="number"
            />
          </Grid>
          {/* Working Days */}
          <Grid item xs={12} sm={8}>
            <WorkingDays
              days={formik.values.days}
              onChangeHandler={formik.handleChange}
              daysList={daysList}
              error={formik.touched.days && Boolean(formik.errors.days)}
              helperText={formik.touched.days && formik.errors.days}
            />
          </Grid>
          {/* Merchants */}
          <Grid item xs={12}>
            <CompanyInput
              selectedCompany={formik.values.company}
              selectedCompanyId={formik.values.company_id}
              onChangeHandler={formik.handleChange}
              merchantsList={companiesList}
            />
          </Grid>
          {/* map */}
          <Grid item xs={12}>
            <BranchMap
              lngProps={formik.values.lng}
              latProps={formik.values.lat}
              onChangeHandler={formik.handleChange}
              errorLat={formik.touched.lat && Boolean(formik.errors.lat)}
              helperTextLat={formik.touched.lat && formik.errors.lat}
              errorLng={formik.touched.lng && Boolean(formik.errors.lng)}
              helperTextLng={formik.touched.lng && formik.errors.lng}
              contentProps={`<div class="info-window-content"><p><strong>Address:</strong> ${formik.values.address}</p></div>`}
            />
          </Grid>
        </Grid>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          className={classes.submit}
          type="submit"
        >
          {branch ? "Update Branch" : "Add Branch"}
        </Button>
      </form>
    </div>
  );
};

export default Branch;
