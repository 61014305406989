import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Table from "components/Admin/Collectables/Collectables";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpired,
  bounceCollectable,
  reviveExpired,
} from "store/actions/collectables";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
};

const useStyles = makeStyles(styles);

const Expired = () => {
  const expiredData = useSelector((state) => state.collectables.expired);
  const dispatch = useDispatch();
  const [page, setPage] = useState(expiredData ? expiredData.pag.page : 1);
  const [amount, setAmount] = useState(
    expiredData ? expiredData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    expiredData ? expiredData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    expiredData ? expiredData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    expiredData ? expiredData.pag.search_by : "model_name"
  );
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getExpired(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  const bounceRecycableHandler = (id) => {
    dispatch(
      bounceCollectable(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };
  const reviveRecycableHandler = (id) => {
    dispatch(
      reviveExpired(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Expired Collectables</h4>
        </CardHeader>
        <CardBody>
          {expiredData ? (
            <Table
              rows={expiredData.data}
              totalRows={expiredData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchValue={searchValue}
              searchHandler={setSearchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              isSelectable
              canRevive
              showRecycable={showRecycableHandler}
              bounceRecycable={bounceRecycableHandler}
              reviveRecycable={reviveRecycableHandler}
            />
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Expired;
