import { whiteColor } from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
import { blackColor } from "assets/jss/material-dashboard-react";

export default makeStyles((theme) => ({
  footer: {
    flex: "1",
    paddingRight: "30px",
    textAlign: "end",
  },
  a: {
    color: blackColor,
    "&:hover": {
      textDecoration: "underline",
      color: blackColor,
    },
    "&:focus": {
      color: blackColor,
    },
  },
  footerLinks: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-arround",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
}));
