import React from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Map from "./CollectableMap";
import Main from "./mainInfo";
import Branch from "./BranchInfo";
import Images from "./Images";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import useStyles from "./CollectableStyle";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const CollectableInfo = ({ collectable }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.container}>
      <div className={classes.containerMain}>
        <div className={classes.mainImageContainer}>
          <Link to={`/admin/users/clients/${collectable.client_id}`}>
            <Avatar
              alt={collectable.client_name}
              src={collectable.client_image}
              className={classes.mainImage}
            >
              <span style={{ textTransform: "uppercase" }}>
                {collectable.client_name[0]}
              </span>
            </Avatar>
          </Link>
        </div>
        <div>
          <Link to={`/admin/users/clients/${collectable.client_id}`}>
            <span className={classes.content}>{collectable.client_name}</span>
          </Link>
        </div>
        <div>
          <span className={classes.label}>Phone:</span>
          <span className={classes.content}>{collectable.client_phone}</span>
        </div>
        <div>
          <span className={classes.label}>Status:</span>
          <span className={classes.content}>{collectable.status}</span>
        </div>
        <div>
          <span className={classes.label}>Damaged:</span>
          <span className={classes.content}>
            {collectable.is_damaged === "not_good" ? "Not Good" : "Good"}
          </span>
        </div>
        <div>
          <span className={classes.label}>Collected:</span>
          <span className={classes.content}>
            {collectable.is_collected === 1 ? "True" : "False"}
          </span>
        </div>
        {collectable.is_collected === 1 && collectable.agent_name && (
          <div>
            <span className={classes.label}>Collected By:</span>
            <span className={classes.content}>{collectable.agent_name}</span>
            <span className={classes.label} style={{ marginLeft: "20px" }}>
              At (Collected Time):
            </span>
            <span className={classes.content}>
              {new Date(collectable.collected_time * 1000).toLocaleString()}
            </span>
          </div>
        )}
        <div>
          <span className={classes.label}>Archived:</span>
          <span className={classes.content}>
            {collectable.is_archived === 1 ? "True" : "False"}
          </span>
        </div>
        <div>
          <span className={classes.label}>Expired:</span>
          <span className={classes.content}>
            {collectable.is_expired === 1 ? "True" : "False"}
          </span>
        </div>
      </div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="collectable Info Tabs"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            label="Main"
            {...a11yProps(0)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Images"
            {...a11yProps(1)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Branch"
            {...a11yProps(2)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Map"
            {...a11yProps(2)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Main collectable={collectable} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Images collectable={collectable} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Branch collectable={collectable} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div style={{ margin: "20px auto" }}>
          <Map
            lngProps={collectable.branch_lng}
            latProps={collectable.branch_lat}
            contentProps={`<div class="info-window-content"><p><strong>Address:</strong> ${collectable.branch_address}</p><p><strong>Landmark:</strong> ${collectable.branch_landmark}</p></div>`}
          />
        </div>
      </TabPanel>
    </div>
  );
};

export default CollectableInfo;
