import axios from "axios";
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return req;
});

//auth
export const login = (formData) => API.post("/login", formData);
export const logout = () => API.post("/logout");
export const me = () => API.get("/me");
export const refreshToken = () => API.get("/refresh");
export const resetPassword = (formData) =>
  API.post("/reset-password", formData);
export const setPassword = (formData) => API.post("/setup-password", formData);
export const forgetPassword = (email) =>
  API.post("/forget-password/send-email", email);

// home link
export const getHome = () => API.get(`/home`);
export const getAllCompanies = () => API.get("/companies/get-all");
export const getAllCategories = () => API.get("/categories/get-all");
export const getAllSubCategories = () => API.get("/sub-categories/get-all");
export const getAllBranches = () => API.get("/branches/get-all");
export const getDays = () => API.get("/days/get-all");
export const getPriorities = () => API.get("/priorities/get-all");

//users
//clients
export const getUsersClients = (data) => API.post("/clients/index", data);
export const getUsersClient = (clientId) =>
  API.get(`/clients/show/${clientId}`);
export const deleteUsersClients = (ClientsIdsArray) =>
  API.post("/clients/destroy", ClientsIdsArray);
export const changeStatusUsersClients = (ClientsIdsArray) =>
  API.post("/clients/change-status", ClientsIdsArray);

//Branches
export const getUsersBranches = (data) =>
  API.post("/users/branches/index", data);
export const deleteUsersBranches = (userBranchId) =>
  API.post("/users/branches/destroy", userBranchId);
export const createUsersBranches = (newUser) =>
  API.post("/users/branches/store", newUser);

//SubAdmins
export const getUsersAdmins = (data) => API.post("/users/admins/index", data);
export const deleteUsersAdmins = (userIds) =>
  API.post("/users/admins/destroy", userIds);
export const createUsersAdmins = (newUser) =>
  API.post("/users/admins/store", newUser);
export const UpdateUsersAdmins = (newUser) =>
  API.post("/users/admins/update", newUser);

//Recyclers
export const getUsersRecyclers = (data) =>
  API.post("/users/recycler/index", data);
export const deleteUsersRecyclers = (userIds) =>
  API.post("/users/recycler/destroy", userIds);
export const changeActiveUsersRecyclers = (userIds) =>
  API.post("/users/recycler/change-active", userIds);
export const createUsersRecyclers = (newUser) =>
  API.post("/users/recycler/store", newUser);
export const UpdateUsersRecyclers = (newUser) =>
  API.post("/users/recycler/update", newUser);

//Collectables
export const fetchWaiting = (data) =>
  API.post("/collectables/waiting/index", data);
export const fetchArchived = (data) =>
  API.post("/collectables/archived/index", data);
export const fetchApproved = (data) =>
  API.post("/collectables/approval/index", data);
export const fetchRejected = (data) =>
  API.post("/collectables/rejected/index", data);
export const fetchExpired = (data) =>
  API.post("/collectables/expiring/index", data);
export const getCollectable = (collectableId) =>
  API.get(`/collectables/show/${collectableId}`);
export const approveCollectables = (collectablesIdsArray) =>
  API.post("/collectables/approve", collectablesIdsArray);
export const rejectCollectables = (collectablesIdsArray) =>
  API.post("/collectables/reject", collectablesIdsArray);
export const getRejectReason = () => API.get("/reason-reject-message");
export const bounceCollectable = (collectableId) =>
  API.post(`/collectables/bounce`, collectableId);
export const reviveExpired = (collectablesIdsArray) =>
  API.post("/collectables/new-expiry-date", collectablesIdsArray);

// companies
export const getCompanies = (data) => API.post("/company/index", data);
export const getCompany = (companyId) => API.get(`/company/show/${companyId}`);
export const createCompany = (companyData) =>
  API.post("/company/store/", companyData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const updateCompany = (companyData) =>
  API.post("/company/update/", companyData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const deleteCompany = (companyIds) =>
  API.post("/company/destroy", companyIds);
export const resetCompany = (companyIds) =>
  API.post("/company/reset", companyIds);
export const selectToFirstVoucherCompany = (companyIdObject) =>
  API.post("/company/select-to-first-voucher", companyIdObject);
export const getMainAccountType = () => API.get("/company/type-account-main");
export const getCompanyAnalysisCollectables = (companyId) =>
  API.get(`/company/analysis-collectables/${companyId}`);
export const getCompanyCollectables = (data) =>
  API.post("/company/details-collectables", data);

// branches
export const getBranches = (data) => API.post("/branch/index", data);
export const getBranch = (branchId) => API.get(`/branch/show/${branchId}`);
export const createBranch = (branchData) =>
  API.post("/branch/store", branchData);
export const updateBranch = (branchData) =>
  API.post("/branch/update", branchData);
export const deleteBranch = (branchIds) =>
  API.post("/branch/destroy", branchIds);
export const changeStatusBranch = (branchIds) =>
  API.post("/branch/change-status", branchIds);
export const resetBranch = (branchIds) => API.post("/branch/reset", branchIds);
export const getBranchCollectablesArchived = (data) =>
  API.post("/branch/collectables-archived", data);
export const getBranchCollectablesCollected = (data) =>
  API.post("/branch/collectables-collected", data);
export const branchCollectablesCollectedPdf = (id) =>
  API.get(`/branch/collectables-collected-export-pdf/${id}`);
export const getBranchCollectablesNotCollected = (data) =>
  API.post("/branch/collectables-not-collected", data);

// voucher
export const getVouchers = (data) => API.post("/voucher/index", data);
export const getVoucher = (data) => API.post("/voucher/get-company-id", data);
export const createVoucher = (data) => API.post("/voucher/store", data);
export const deleteVoucher = (data) => API.post("/voucher/destroy", data);
export const deleteSingleVoucher = (data) =>
  API.post("/voucher/destroy-items", data);

// categories
export const getCategories = (data) => API.post("/category/index", data);
export const createCategory = (data) =>
  API.post("/category/store", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const updateCategory = (data) =>
  API.post("/category/update", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const deleteCategory = (data) =>
  API.delete("/category/destroy", { data });
export const changeStatusCategory = (data) =>
  API.post("/category/change-status", data);

// brand
export const getBrands = (data) => API.post("/brand/index", data);
export const createBrand = (data) => API.post("/brand/store", data);
export const updateBrand = (data) => API.post("/brand/update", data);
export const deleteBrand = (data) => API.delete("/brand/destroy", { data });

// sub-categories
export const getSubCategories = (data) => API.post("/sub-category/index", data);
export const createSubCategory = (data) =>
  API.post("/sub-category/store", data);
export const updateSubCategory = (data) =>
  API.post("/sub-category/update", data);
export const deleteSubCategory = (data) =>
  API.delete("/sub-category/destroy", { data });

// update api
export const getMobileUpdate = () => API.get(`/updates/fetch`);
export const updateMobileData = (data) => API.post("/updates/update", data);

//manager & agent
export const getManagerHome = () => API.get(`/branch/home`);

export const managerSearchCode = (code) =>
  API.post("/branch/collectables/search", code);
export const managerFetchCollected = (paData) =>
  API.post("/branch/collectables/index", paData);
export const managerFetchArchived = (paData) =>
  API.post("/branch/collectables/archive/index", paData);
export const getCollectableManager = (collectableId) =>
  API.get(`/branch/collectables/show/${collectableId}`);
export const managerCollect = (id) =>
  API.post("/branch/collectables/collected", id);
export const managerArchiveAll = (passData) =>
  API.post("/branch/collectables/archived", passData);
export const managerExportArchive = (paData) =>
  API.post("/branch/collectables/archive/export", paData);

export const managerFetchSupport = (paData) =>
  API.post("/branch/support/index", paData);
export const managerCreateNewSupport = (newData) =>
  API.post("/branch/support/store", newData);

export const managerVoucherSeacrch = (code) =>
  API.post("/branch/voucher/search", code);
export const managerVoucherIsUsed = (code) =>
  API.post("/branch/voucher/used-code", code);

// manager only
export const managerFetchUsers = (paData) =>
  API.post("/branch/users/index", paData);
export const managerCreateNewUser = (newUser) =>
  API.post("/branch/users/store", newUser);
export const managerDeleteUsers = (idsArray) =>
  API.post("/branch/users/destroy", idsArray);

export const downloadFile = (url) =>
  axios.get(url, {
    headers: {
      "Content-Type": "application/pdf",
    },
  });
