import React from "react";
import Header from "components/Shared/CutsomHeader/CustomHeader";
import Footer from "components/Shared/CustomFooter/CustomFooter";
import useStyles from "./homeStyle";

export default function Home() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <h1 className={classes.title}>E-Tadweer Dashboard</h1>
      </div>
      <Footer />
    </div>
  );
}
