import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export default function Branches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchHandler,
  searchValue,
  selectedSearchBy,
  handleSearchByChange,

  showCollected,
}) {
  const searchByOptions = [
    {
      value: "name",
      label: "Name",
    },
    {
      value: "name_ar",
      label: "Name Arabic",
    },
    {
      value: "phone",
      label: "Phone Number",
    },
    {
      value: "company_name",
      label: "Company Name",
    },
    {
      value: "status",
      label: "Status",
    },
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Id",
    },
    {
      id: "name",
      sortable: true,
      label: "Name",
    },
    {
      id: "name_ar",
      sortable: true,
      label: "Name Arabic",
    },
    {
      id: "phone",
      sortable: true,
      label: "Phone",
    },
    {
      id: "company_name",
      sortable: true,
      label: "Company",
    },
    {
      id: "is_closed",
      sortable: false,
      label: "Status",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {"# " + row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name_ar}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.phone_number}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.company}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_closed}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="show collected">
                      <IconButton
                        aria-label="show"
                        onClick={() => showCollected(row.id)}
                      >
                        <VisibilityIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={8} align="center">
            No Branches Found
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        handleFilterReset
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={null}
        toolbarButtons={null}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
      />
    </React.Fragment>
  );
}
