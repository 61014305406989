import React, { useState } from "react";
import { Grid, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Chip from "@material-ui/core/Chip";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./BranchStyle";

const useStyles = makeStyles(styles);

export default function CompanyInput({
  onChangeHandler,
  selectedCompany,
  selectedCompanyId,
  merchantsList
}) {
  const classes = useStyles();

  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };
  const [ open, setOpen ] = React.useState(false);
  const handleChange = (e) => {
    setOpen(false);
    onChangeHandler(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Grid item xs={12}>
      <InputLabel className={classes.label}>Merchants:</InputLabel>
      <Select
        id="company_id"
        name="company_id"
        label="merchants"
        className={classes.selectInput}
        value={selectedCompanyId}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        input={<Input />}
        inputProps={{ "aria-label": "Without label" }}
        // error={merchantsError}
      >
        {merchantsList.map((merchant) => (
          <MenuItem key={merchant.id} value={merchant.id}>
            <ListItemText primary={merchant.name} />
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
    </Grid>
  );
}
