import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Shared/CustomTable/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import EnhancedTableHead from "./CollactablesTableHead";
import EnhancedTableToolbar from "./CollectablesTableToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function EnhancedTable({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchHandler,
  searchValue,
  showRecycable,
  showArchive,
  showFilter,
  selectedSearchBy,
  handleSearchByChange,
  startDate,
  endDate,
  changeStartDate,
  changeEndDate,
  archiveRecycable,
  handleExport
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [search, setSearch] = React.useState(searchValue);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleRequestSort = (event, property) => {
    if (property !== "options") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setPage(1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const setSearchHandler = (e) => {
    setSearch(e.target.value);
  };
  const clearSearchHandler = () => {
    setSearch("");
    searchHandler("");
  };

  const tableToolbar = (
    <EnhancedTableToolbar
      setSearchHandler={setSearchHandler}
      clearSearchHandler={clearSearchHandler}
      searchHandler={() => {
        searchHandler(search);
      }}
      searchValue={search}
      showArchive={showArchive}
      showFilter={showFilter}
      selectedSearchBy={selectedSearchBy}
      handleSearchByChange={handleSearchByChange}
      startDate={startDate}
      endDate={endDate}
      changeStartDate={changeStartDate}
      changeEndDate={changeEndDate}
      handleExport={handleExport}
    />
  );

  const tableHead = (
    <EnhancedTableHead
      classes={classes}
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
    />
  );
  return (
    <React.Fragment>
      <Table
        tableHead={tableHead}
        tableToolbar={tableToolbar}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      >
        {rows.length !== 0 ? (
          rows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow key={row.id} hover>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                  onClick={() => showRecycable(row.id)}
                >
                  {"# " + row.model_name}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.is_collected === 0 ? "Not Collected" : "Collected"}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.created_at}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={8} align="center">
              No Collectables Found
            </TableCell>
          </TableRow>
        )}
      </Table>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
