import React from "react";
import { Typography, Container, Paper } from "@material-ui/core";
import Header from "components/Shared/CutsomHeader/CustomHeader";
import Footer from "components/Shared/CustomFooter/CustomFooter";
import useStyles from "./recyclesStyle";

export default function Recycles() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "scroll";
  }, []);
  const recyclesData = `<div class="col-12 text">
  <table class="table">
      <thead>
          <tr>
              <th>م</th>
              <th>اسم الشركة</th>
              <th>نوع النشاط</th>
              <th>بيانات التواصل</th>
          </tr>
      </thead>
      <tbody>
          <tr>
              <th scope="row">1</th>
              <td>مصنع صهر وتكرير وتصنيع الرصاص</td>
              <td>تدوير بطاريات الرصاص</td>
              <td>القطعة رقم 7 - حوض الزهار رقم 8- عرب العليقات - المنطقة الصناعية - مركز الخانكة - محافظة القليوبية</td>
          </tr>
          <tr>
              <th scope="row">2</th>
              <td>الشركة المصرية لصهر وتكرير وتصنيع الرصاص</td>
              <td>تدوير بطاريات الرصاص</td>
              <td>القطعة رقم (32/3) - المنطقة الصناعية A1 - مدينة العاشر من رمضان</td>
          </tr>
          <tr>
              <th scope="row">3</th>
              <td>الشركة المتحدة للبطاريات</td>
              <td>تدوير بطاريات الرصاص</td>
              <td>العاشر من رمضان - المنطقة الصناعية A4</td>
          </tr>
          <tr>
              <th scope="row">4</th>
              <td>شركة كلورايد ايجيبت</td>
              <td>تدوير بطاريات الرصاص</td>
              <td>قطعة M11 - التوسعات الشمالية المنطقة الصناعية - جهاز مدينة 6أكتوبر</td>
          </tr>
          <tr>
              <th scope="row">5</th>
              <td>شركة صناعات البلاستيك والكهرباء المصرية (النسر الألمانية)</td>
              <td>تدوير بطاريات الرصاص</td>
              <td>القطعة رقم (7021) -المنطقة الصناعية السابعة - جهاز مدينة السادات</td>
          </tr>
          <tr>
              <th scope="row">6</th>
              <td>شركة إنترناشيونال تكنولوجى جروب ITG</td>
              <td>تدوير المخلفات الإلكترونية والكهربائية</td>
              <td>2/ب القطعة 69 - المنطقة الصناعية السادسة - السادس من أكتوبر الجيزة
                  الهاتف: 01027101009
              </td>
          </tr>
          <tr>
              <th scope="row">7</th>
              <td>شركة جرين كور green core لإعادة التدوير</td>
              <td>تدوير المخلفات الإلكترونية واستخلاص المعادن منها وجمعها</td>
              <td>قطعة 6 بلوك 5 منطقة 8-15 مايو - حلوان - القاهرة
                  الهاتف: 01001812225 01100071744
              </td>
          </tr>
          <tr>
              <th scope="row">8</th>
              <td>مركز الناصرية لمعالجة والتخلص الآمن من المخلفات الخطرة</td>
              <td>استقبال المخلفات الخطرة الغير عضوية "السائلة والصلبة" ومنها مخلفات المعامل</td>
              <td>مركز الناصرية لمعالجة المخلفات الخطرة - مدينة برج العرب - الإسكندرية</td>
          </tr>
          <tr>
              <th scope="row">9</th>
              <td>مصنع تدوير المخلفات الإلكترونية والكهربائية (مؤسسة العرايشى) للتجارة والصناعة.</td>
              <td>تكسير وفرم المخلفات الإلكترونية</td>
              <td>القطعة رقم (15) - المنطقة الصناعية الأولى - مدينة 15 مايو - محافظة القاهرة.</td>
          </tr>
          <tr>
              <th scope="row">10</th>
              <td>مصنع تدوير المخلفات الإلكترونية (تريبل رى).</td>
              <td>تدوير المخلفات الإلكترونية</td>
              <td>القطعة رقم (95) - المنطقة الصناعية - منطقة المخازن الصناعات الصغيرة (مشروع الشباب) - السادس من أكتوبر.</td>
          </tr>
          <tr>
              <th scope="row">11</th>
              <td>مصنع تدوير المخلفات الإلكترونية (ريسيكل كى)</td>
              <td>عمليات الفرز والتدوير للمخلفات الإلكترونية</td>
              <td>القطعة رقم (10) - مجمع الصناعات الصغيرة - منطقة المطورين بالمنطقة الصناعية - مدينة السادات. 01006245592</td>
          </tr>
      </tbody>
  </table>
</div>`;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <Container component="main" maxWidth="lg">
          <div className={classes.header}>
            <Typography className={classes.title} variant="h4">
              قائمة المصانع والشركات الرسمية العاملة فى مجال تدوير المخلفات
              الإلكترونية والكهربائية وتدوير بطاريات الرصاص والتخلص من (النوعيات
              المختلفة من المخلفات) والحاصلة على موافقات بيئية من جهاز شئون
              البيئة
            </Typography>
          </div>
          <Paper className={classes.paper} elevation={3}>
            <div
              style={{ textAlign: "right", direction: "rtl" }}
              dangerouslySetInnerHTML={{ __html: recyclesData }}
            ></div>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
