import React from "react";
import { Grid, InputLabel, Switch } from "@material-ui/core";
import Input from "components/Shared/CustomInput/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./CompanyStyle";

const useStyles = makeStyles(styles);

export default function VoucherTypeInput({
  isSingleVoucher,
  voucherSingleCode,
  onChangeHandler,
  voucherError,
  voucherHelperText,
  voucherCount,
  voucherCountError,
  voucherCountHelperText,
}) {
  const classes = useStyles();

  const handleChangeSwitch = () => {
    let change;
    if (isSingleVoucher === 1) {
      change = {
        target: { name: "is_single_voucher", value: 0 },
      };
    } else {
      change = {
        target: { name: "is_single_voucher", value: 1 },
      };
    }

    onChangeHandler(change);
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          className={classes.switchContainer}
          control={
            <Switch
              classes={{
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={isSingleVoucher === 1}
              onChange={handleChangeSwitch}
              name="is_single_voucher"
            />
          }
          label={
            <InputLabel className={classes.label} style={{ margin: "auto" }}>
              Is Single Voucher Code
            </InputLabel>
          }
        />
      </Grid>
      {isSingleVoucher === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Voucher Single Code:
            </InputLabel>
            <Input
              id="voucher_single_code"
              name="voucher_single_code"
              label="Enter Code"
              value={voucherSingleCode}
              onChange={onChangeHandler}
              error={voucherError}
              helperText={voucherHelperText}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Voucher Count:</InputLabel>
            <Input
              id="count_voucher"
              name="count_voucher"
              label="Enter Count"
              value={voucherCount}
              onChange={onChangeHandler}
              error={voucherCountError}
              helperText={voucherCountHelperText}
              type="number"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
