import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import styles from "./sidebarStyle";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles(styles);

const SidebarSubLink = ({
  parentRoute,
  color,
  route,
  activeRoute,
  clickHandler,
}) => {
  const classes = useStyles();
  let activePro = " ";
  let listItemClasses;
  listItemClasses = classNames({
    [" " + classes[color]]: activeRoute(
      route.layout + parentRoute.path + route.path
    ),
  });

  return (
    <div>
      <NavLink
        to={route.layout + parentRoute.path + route.path}
        className={activePro + classes.item}
        activeClassName="active"
        onClick={clickHandler}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          <ListItemText
            primary={route.name}
            className={classNames(classes.itemText, classes.whiteFont)}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    </div>
  );
};

export default SidebarSubLink;
