import React, { useState } from "react";
import { SketchPicker } from "react-color";

function ColorPicker({ textColor, setTextColor }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  return (
    <div>
      <div
        style={{
          backgroundColor: "#eee",
          width: "100px",
          height: "20px",
          margin: "10px 0",
          cursor: "pointer",
          border: "1px solid #ced4da",
          borderRadius: ".25rem",
          padding: "10px"
        }}
        onClick={() => setShowColorPicker((prev) => !prev)}
      >
        <div
          style={{
            backgroundColor: textColor,
            width: "100%",
            height: "100%"
          }}
        ></div>
      </div>
      {showColorPicker && (
        <SketchPicker
          color={textColor}
          onChangeComplete={(color) =>
            setTextColor({
              target: { name: "coupon_text_color", value: color.hex }
            })
          }
        />
      )}
    </div>
  );
}

export default ColorPicker;
