import { whiteColor } from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  footer: {
    flex: "1",
    paddingLeft: "30px",
  },
  a: {
    color: whiteColor,
    "&:hover": {
      textDecoration: "underline",
      color: whiteColor,
    },
    "&:focus": {
      color: whiteColor,
    },
  },
  footerLinks: {
    display: "flex",
    justifyContent: "space-arround",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
}));
