import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
const headCells = [
  {
    id: "model_name",
    numeric: false,
    disablePadding: true,
    label: "Recyclable Name",
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "user_phone",
    numeric: false,
    disablePadding: true,
    label: "User Phone",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  { id: "options", numeric: false, disablePadding: true, label: "Options" },
];

const EnhancedTableHead = (props) => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" padding="normal">
            <span>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default EnhancedTableHead;
