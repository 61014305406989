import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Shared/Navbars/Navbar.js";
import Footer from "components/Shared/Footer/CustomFooter";
import Sidebar from "components/Shared/Sidebar/Sidebar.js";

import {
  adminRoutes,
  subAdminRoutes,
  managerRoutes,
  agentRoutes,
  recyclerRoutes,
  adminLinks,
  subAdminLinks,
  managerLinks,
  agentLinks,
  recyclerLinks,
} from "routes.js";
import { getMe } from "store/actions/auth";
import styles from "./Styles";

// import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logoWhite.png";

let ps;

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const [dashboardRoutes, setDashboardRoutes] = React.useState(null);
  const [dashboardLinks, setDashboardLinks] = React.useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useSelector((state) => state.auth.authData);
  React.useEffect(() => {
    if (!authData) {
      dispatch(getMe(history));
    } else {
      if (authData.role === "admin") {
        setDashboardRoutes(adminRoutes);
        setDashboardLinks(adminLinks);
      } else if (authData.role === "sub_admin") {
        setDashboardRoutes(subAdminRoutes);
        setDashboardLinks(subAdminLinks);
      } else if (authData.role === "manager") {
        setDashboardRoutes(managerRoutes);
        setDashboardLinks(managerLinks);
      } else if (authData.role === "agent") {
        setDashboardRoutes(agentRoutes);
        setDashboardLinks(agentLinks);
      } else if (authData.role === "recycler") {
        setDashboardRoutes(recyclerRoutes);
        setDashboardLinks(recyclerLinks);
      }
    }
  }, [authData]);

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("green");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardLinks}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel}>
        <Navbar handleDrawerToggle={handleDrawerToggle} {...rest} />
        <div className={classes.content}>
          <div className={classes.container}>{dashboardRoutes}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
