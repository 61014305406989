import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button.js";
import ClientInfo from "components/Admin/Users/Clients/Client/ClientInfo";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import { getUsersClient } from "store/actions/users";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  loadingContainer: {
    textAlign: "center"
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important"
  }
};

const useStyles = makeStyles(styles);

const Client = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const selectedClient = useSelector((state) => state.users.selectedClient);
  useEffect(() => {
    dispatch(getUsersClient(id, history));
  }, [id, dispatch]);
  return (
    <div>
      {selectedClient ? (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparentBlack"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ margin: "auto", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Client Info</h4>
            </CardHeader>
            <CardBody>
              <ClientInfo client={selectedClient} />
            </CardBody>
          </Card>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </div>
      )}
    </div>
  );
};

export default Client;
