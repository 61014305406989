import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/Shared/CustomInput/CustomInput.js";
import Button from "components/Shared/CustomButtons/Button.js";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    marginRight: "20px"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  searchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "75%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block"
    }
  },
  searchInput: {
    margin: "0 10px",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      width: "70%"
    }
  },
  searchInputContainer: {
    margin: "0 10px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%"
    }
  },
  searchByContainer: {
    margin: "0 10px",
    flex: 2,
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "90%"
    }
  },
  searchByInput: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "90%",
      display: "block"
    }
  },
  filterInput: {
    marginRight: "10px",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%"
    }
  },
  filterButton: {
    flex: 1,
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "80%",
      margin: "10px auto",
      marginBottom: "20px"
    }
  },
  clearButton: {
    width: "15%",
    fontSize: "12px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "80%",
      margin: "10px auto",
      marginBottom: "20px"
    }
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    setSearchHandler,
    searchHandler,
    clearSearchHandler,
    searchValue,
    selectedSearchBy,
    handleSearchByChange,
    selectedToolbarButtons,
    toolbarButtons,
    showSearchBy,
    searchByOptions,
    showFilter,
    filterTitle,
    selectedFilter,
    handleFilterChange,
    filterOptions,
    handleFilterReset
  } = props;

  const handleSearchBy = (event) => {
    handleSearchByChange(event.target.value);
  };

  return (
    <Toolbar className={classes.root}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <div>{selectedToolbarButtons}</div>
      ) : (
        <div className={classes.container}>
          {toolbarButtons}
          <div className={classes.searchContainer}>
            <div className={classes.searchInputContainer}>
              <CustomInput
                formControlProps={{
                  className: classes.searchInput
                }}
                inputProps={{
                  placeholder: "Search",
                  inputProps: {
                    "aria-label": "Search"
                  },
                  onChange: setSearchHandler,
                  value: searchValue
                }}
              />
              <Button
                className={classes.searchButton}
                color="transparentBlack"
                aria-label="edit"
                justIcon
                round
                onClick={searchHandler}
              >
                <Search />
              </Button>
            </div>
            {showSearchBy && (
              <div className={classes.searchByContainer}>
                <InputLabel shrink htmlFor="search-by">
                  Search By
                </InputLabel>
                <NativeSelect
                  className={classes.searchByInput}
                  value={selectedSearchBy}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: "search-by",
                    id: "search-by"
                  }}
                >
                  {searchByOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </NativeSelect>
              </div>
            )}
            <Button
              className={classes.clearButton}
              color="info"
              onClick={clearSearchHandler}
            >
              clear
            </Button>
          </div>
          {showFilter && (
            <div className={classes.filterContainer}>
              <div className={classes.filterInputContainer}>
                <InputLabel shrink htmlFor="filter-input">
                  {filterTitle}
                </InputLabel>
                <NativeSelect
                  className={classes.filterInput}
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  inputProps={{ id: "filter-input" }}
                >
                  <option value="">None</option>
                  {filterOptions.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </NativeSelect>
              </div>
              <Button
                className={classes.filterButton}
                color="info"
                aria-label="edit"
                justIcon
                round
                onClick={handleFilterReset}
              >
                reset
              </Button>
            </div>
          )}
        </div>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
