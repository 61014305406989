import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AdminDashboard from "components/Admin/Home/AdminHome";
import ManagerDashboard from "components/Manager/Home/ManagerHome";

export default function Dashboard() {
  const [homeComponent, setHomeComponent] = useState(null);
  const [userRole, setUserRole] = useState("");
  const authData = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (authData.role === "admin") {
      setHomeComponent(<AdminDashboard />);
      setUserRole("Admin");
    } else if (authData.role === "sub_admin") {
      setHomeComponent(<AdminDashboard />);
      setUserRole("Sub Admin");
    } else if (authData.role === "manager") {
      setHomeComponent(<ManagerDashboard />);
      setUserRole("Manager");
    } else if (authData.role === "agent") {
      setHomeComponent(<ManagerDashboard />);
      setUserRole("Agent");
    } else if (authData.role === "recycler") {
      setHomeComponent(null);
      setUserRole("Recycler");
    }
  }, [authData, dispatch]);

  return (
    <div>
      <h3>{`Welcome ${userRole}, ${authData.name}`}</h3>
      {homeComponent}
    </div>
  );
}
