import * as api from "../api";
import * as actionsType from "./actionsType";

export const getWaiting = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_WAITING,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.fetchWaiting(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_WAITING,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getRejectReason = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_REJECT_REASON,
      payload: null,
    });
    const { data } = await api.getRejectReason();
    dispatch({
      type: actionsType.GET_REJECT_REASON,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getArchived = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.fetchArchived(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getApproved = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_APPROVED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.fetchApproved(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_APPROVED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getRejected = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_REJECTED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.fetchRejected(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_REJECTED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getExpired = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_EXPIRED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.fetchExpired(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_EXPIRED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_COMPANIES,
      payload: null,
    });
    const { data } = await api.getAllCompanies();
    dispatch({
      type: actionsType.GET_COLLECTABLES_COMPANIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const approveCollectables = (
  idsArray,
  formData,
  history,
  isWaiting
) => async (dispatch) => {
  try {
    await api.approveCollectables({
      ids: idsArray,
    });
    if (isWaiting) {
      dispatch(getWaiting(formData, history));
    } else {
      dispatch(getRejected(formData, history));
    }

    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectables Approved Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const rejectCollectables = (
  idsArray,
  reasonId,
  formData,
  history
) => async (dispatch) => {
  try {
    await api.rejectCollectables({
      ids: idsArray,
      reason_id: reasonId,
    });
    dispatch(getWaiting(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectables Rejected Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const reviveExpired = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.reviveExpired({
      ids: idsArray,
    });
    dispatch(getExpired(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "expiry date has been renew successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const bounceCollectable = (id, formData, history) => async (
  dispatch
) => {
  try {
    await api.bounceCollectable({
      collectable_id: id,
    });
    dispatch(getApproved(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable has been bounced Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getCollectable = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLE,
      payload: null,
    });
    const { data } = await api.getCollectable(id);
    dispatch({
      type: actionsType.GET_COLLECTABLE,
      payload: data.data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const approveSingle = (id, history) => async (dispatch) => {
  try {
    await api.approveCollectables({
      ids: [id],
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable Approved Successfully",
      },
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const rejectSingle = (id, reasonId, history) => async (dispatch) => {
  try {
    await api.rejectCollectables({
      ids: [id],
      reason_id: reasonId,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable rejected Successfully",
      },
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const reviveSingle = (id, history) => async (dispatch) => {
  try {
    await api.reviveExpired({
      ids: [id],
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "expiry date has been renew successfully",
      },
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const bounceSingle = (id, history) => async (dispatch) => {
  try {
    await api.bounceCollectable({
      collectable_id: id,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable has been bounced Successfully",
      },
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

// manager & agent dashboard

export const getCollectedManager = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_COLLECTED,
      payload: null,
    });
    const { data } = await api.managerFetchCollected(formData);
    dispatch({
      type: actionsType.GET_COLLECTABLES_COLLECTED,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getArchivedManager = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED,
      payload: null,
    });
    const { data } = await api.managerFetchArchived(formData);
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const searchCode = (code, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_IS_SEARCHING,
      payload: { loading: true, message: null },
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: null,
    });
    dispatch({
      type: actionsType.SEARCH_CODE,
      payload: null,
    });
    const { data } = await api.managerSearchCode(code);
    dispatch({
      type: actionsType.SEARCH_CODE,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_IS_SEARCHING,
      payload: { loading: false, message: null },
    });
  } catch (error) {
    if (error.response.status === 422) {
      dispatch({
        type: actionsType.SET_IS_SEARCHING,
        payload: { loading: false, message: "No Recycable Found" },
      });
    } else {
      dispatch({
        type: actionsType.SET_IS_SEARCHING,
        payload: { loading: false, message: null },
      });
      let errorMessage =
        error.response.status + " " + error.response.data.message;
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "danger", text: errorMessage },
      });
      if (error.response.status === 401 || error.response.status === 403) {
        history.push("/");
      }
    }
  }
};

export const getCollectableManager = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLE_MANAGER,
      payload: null,
    });
    dispatch({
      type: actionsType.SEARCH_CODE,
      payload: null,
    });
    const { data } = await api.getCollectableManager(id);
    dispatch({
      type: actionsType.GET_COLLECTABLE_MANAGER,
      payload: data.data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const collect = (id, history) => async (dispatch) => {
  try {
    await api.managerCollect({
      collectable_id: id,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable collected Successfully",
      },
    });
    dispatch({
      type: actionsType.SEARCH_CODE,
      payload: null,
    });
    dispatch({
      type: actionsType.GET_COLLECTABLE_MANAGER,
      payload: null,
    });
    history.replace("/admin/dashboard");
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const archiveAll = (passData, formData, history) => async (dispatch) => {
  try {
    const res = await api.managerArchiveAll(passData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable archived Successfully",
      },
    });
    dispatch({
      type: actionsType.GET_COLLECTABLES_COLLECTED,
      payload: null,
    });
    const { data } = await api.managerFetchCollected(formData);
    dispatch({
      type: actionsType.GET_COLLECTABLES_COLLECTED,
      payload: data.data,
    });
    // console.log(res.data);
    window.open(res.data, "_blank");
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const exportArchive = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.managerExportArchive(formData);
    window.open(data, "_blank");
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
