import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string("Enter name").required("Name is required")
});

const CreateNewUser = ({ createUser }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      name: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });
  const classes = useStyles();

  const handleSubmit = (values) => {
    createUser({
      name: values.name,
      email: values.email
    });
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px"
            }}
          >
            Name:
          </FormLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </GridItem>

        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px"
            }}
          >
            Email:
          </FormLabel>
          <Input
            id="email"
            name="email"
            label="Enter Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Add Branch Agent
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default CreateNewUser;
