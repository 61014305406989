import * as React from "react";
import { Grid, InputLabel } from "@material-ui/core";
import Input from "components/Shared/CustomInput/Input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./CompanyStyle";

const useStyles = makeStyles(styles);

export default function DiscountTypeInput({
  discountType,
  onChangeHandler,
  discountPercent,
  discountFixed,
  discountPercentError,
  discountPercentHelperText,
  discountFixedError,
  discountFixedHelpText
}) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel className={classes.label}>Discount Type</FormLabel>
          <RadioGroup
            aria-label="type_discount"
            name="type_discount"
            value={discountType}
            onChange={onChangeHandler}
          >
            <FormControlLabel
              value="percentage"
              control={
                <Radio color="default" className={classes.customCheckbox} />
              }
              label="Percentage"
            />
            <FormControlLabel
              value="fixed"
              control={
                <Radio color="default" className={classes.customCheckbox} />
              }
              label="Fixed"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {discountType === "percentage" && (
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            Discount Percentage:
          </InputLabel>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Input
              id="discount_percentage"
              name="discount_percentage"
              label="Enter Discount percentage"
              value={discountPercent}
              onChange={onChangeHandler}
              error={discountPercentError}
              helperText={discountPercentHelperText}
              multiline={false}
              type="number"
            />
            <span style={{ padding: "5px" }}>%</span>
          </div>
        </Grid>
      )}
      {discountType === "fixed" && (
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Discount Fixed:</InputLabel>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Input
              id="discount_fixed"
              name="discount_fixed"
              label="Enter Discount Fixed"
              value={discountFixed}
              onChange={onChangeHandler}
              error={discountFixedError}
              helperText={discountFixedHelpText}
              multiline={false}
              type="number"
            />
            <span style={{ padding: "5px" }}>EGP</span>
          </div>
        </Grid>
      )}
      {discountType !== "fixed" && discountType !== "percentage" && (
        <Grid item xs={12}>
          <p style={{ color: "red" }}>Please Choose a discount Type</p>
        </Grid>
      )}
    </>
  );
}
