import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Admin/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import Company from "components/Admin/Companies/Company/Company";
import { updateCompany, getCompany } from "store/actions/companies";
import CompanyNumbers from "components/Admin/Companies/CompanyNumbers/CompanyNumbers";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important",
  },
});

const useStyles = makeStyles(styles);

const Merchant = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const { company, mainCompanies, companyNumbers } = useSelector(
    (state) => state.companies
  );
  useEffect(() => {
    dispatch(getCompany(id, history));
  }, [id, dispatch]);

  const updateHandler = (data) => {
    data.id = id;
    dispatch(updateCompany(data, history));
  };

  console.log(company);
  return (
    <div>
      <CompanyNumbers companyNumbers={companyNumbers} />
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparentBlack"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ margin: "auto", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Company Info</h4>
          </CardHeader>
          <CardBody>
            {company && mainCompanies ? (
              <Company
                company={company}
                merchantsList={mainCompanies}
                isCreate={false}
                handleUpdate={updateHandler}
                handleCreate={null}
              />
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress className={classes.loading} />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      {/* 
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal> */}
    </div>
  );
};

export default Merchant;
