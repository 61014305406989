import React from "react";

function Tutorial({ title, yid }) {
  return (
    <div>
      <div>
        <h5>{title}</h5>
      </div>
      <div>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube-nocookie.com/embed/${yid}?controls=0`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ maxWidth: "100%" }}
        ></iframe>
      </div>
    </div>
  );
}

export default Tutorial;
