import React, { useState, useEffect } from "react";
import Snackbar from "components/Shared/Snackbar/Snackbar.js";
import { useSelector, useDispatch } from "react-redux";
import { removeMessage } from "store/actions/home";

const CustomMessage = () => {
  const message = useSelector((state) => state.home.message);
  const [Notification, setNotification] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (message) {
      setNotification(true);
    }
  }, [message]);

  const closeNotification = () => {
    dispatch(removeMessage());
    setNotification(false);
  };
  return (
    <div>
      {message && (
        <Snackbar
          place="tr"
          color={message.type}
          message={message.text}
          open={Notification}
          closeNotification={closeNotification}
          close
        />
      )}
    </div>
  );
};

export default CustomMessage;
