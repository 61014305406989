import {
  defaultFont,
  primaryColor,
} from "assets/jss/material-dashboard-react.js";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    margin: "20px 5px",
    flexGrow: 1,
    width: "100%",
  },
  cutomTab: {
    ...defaultFont,
    fontWeight: 900,
  },
  selected: {
    color: primaryColor[1] + "!important",
    borderBottomColor: primaryColor[1] + "!important",
  },
  indicator: {
    backgroundColor: primaryColor[1] + "!important",
  },
  containerMain: {
    marginBottom: "20px",
  },
  mainImageContainer: {
    margin: "10px",
  },
  mainImage: {
    width: "50px",
    height: "50px",
    // [theme.breakpoints.down("xs")]: {
    //   width: "25px",
    //   height: "25px",
    //   margin: "0 auto",
    // },
  },
  imageContainer: {
    flex: 1,
    margin: "5px",
  },
  image: {
    height: "auto",
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  modalImage: {
    height: "500px",
    width: "700px",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  otherInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  dimensionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  dimension: {
    flex: 1,
    // textAlign: "center"
  },
  merchantContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  merchant: {
    flex: 1,
    // textAlign: "center",
  },
  label: {
    ...defaultFont,
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  content: {
    ...defaultFont,
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
