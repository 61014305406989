import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  name: yup.string("Enter name").required("Name is required"),
  name_ar: yup.string("Enter name arabic").required("Name arabic is required"),
  description: yup
    .string("Enter description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter description arabic")
    .required("Description arabic is required"),
});

const CreateNewUser = ({
  companies,
  category,
  isCreate,
  createHandler,
  updateHandler,
}) => {
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };

  const [image, setImage] = useState(
    category
      ? category.image
        ? { file: null, data_url: category.image }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [imageError, setImageError] = useState(null);
  let companies_ids = [];
  if (category) {
    if (category.companies.length !== 0) {
      category.companies.map((comp) => companies_ids.push(comp.id));
    }
  }
  const formik = useFormik({
    initialValues: {
      id: !isCreate ? (category ? category.id : "") : "",
      name: category ? category.name : "",
      name_ar: category ? category.name_ar : "",
      description: category ? category.description : "",
      description_ar: category ? category.description_ar : "",
      company_ids: companies_ids,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useStyles();

  const handleSubmit = (values) => {
    const result = { ...values };
    let error = false;
    if (imageError) {
      error = true;
    }
    if (image.file !== null) {
      result.image = image.file;
    } else if (image.data_url === null) {
      setLogoError("Enter Image!");
      error = true;
    }
    if (error === false) {
      if (isCreate) {
        createHandler(result);
      } else {
        updateHandler(result);
      }
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>
        {/* name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name Arabic:</InputLabel>
          <Input
            id="name_ar"
            name="name_ar"
            label="Enter Name Arabic"
            value={formik.values.name_ar}
            onChange={formik.handleChange}
            error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
            helperText={formik.touched.name_ar && formik.errors.name_ar}
            type="text"
          />
        </Grid>
        {/* Description English */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            Description English:
          </InputLabel>
          <Input
            id="description"
            name="description"
            label="Enter Description"
            multiline
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            type="text"
          />
        </Grid>
        {/* Description Arabic */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Description Arabic:</InputLabel>
          <Input
            id="description_ar"
            name="description_ar"
            label="Enter Description Arabic"
            multiline
            value={formik.values.description_ar}
            onChange={formik.handleChange}
            error={
              formik.touched.description_ar &&
              Boolean(formik.errors.description_ar)
            }
            helperText={
              formik.touched.description_ar && formik.errors.description_ar
            }
            type="text"
          />
        </Grid>
        {/* Logo */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Image:</InputLabel>
          <ImageUpload
            image={image}
            setImage={setImage}
            error={imageError}
            setError={setImageError}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Merchants:</InputLabel>
          <Select
            id="company_ids"
            name="company_ids"
            label="merchants"
            className={classes.selectInput}
            value={formik.values.company_ids}
            onChange={formik.handleChange}
            multiple={true}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={findName(companies, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
            // error={merchantsError}
          >
            {companies.map((merchant) => (
              <MenuItem key={merchant.id} value={merchant.id}>
                <Checkbox
                  checked={formik.values.company_ids.indexOf(merchant.id) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={merchant.name} />
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateNewUser;
