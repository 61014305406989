import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getHome } from "store/actions/home";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";

import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardIcon from "components/Shared/Card/CardIcon/CardIcon.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import styles from "./AdminHomeStyle";

function nFormatter(num) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

const useStyles = makeStyles(styles);

export default function AdminDashboard() {
  const [showWaiting, setShowWaiting] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const homeNumbers = useSelector((state) => state.home.homeNumbers);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getHome(history));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div>
      {homeNumbers && (
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowWaiting((prev) => !prev)}
            >
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  {/* <Icon> */}
                  <HourglassFullIcon />
                  {/* </Icon> */}
                </CardIcon>
              </CardHeader>
              <CardBody>
                <p className={classes.cardCategory}>Waiting for approve</p>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowWaiting(true)}
                  onMouseLeave={() => setShowWaiting(false)}
                >
                  {showWaiting
                    ? homeNumbers.waiting
                    : nFormatter(homeNumbers.waiting)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowApprove((prev) => !prev)}
            >
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Icon>check</Icon>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <p className={classes.cardCategory}>Approved Requests</p>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowApprove(true)}
                  onMouseLeave={() => setShowApprove(false)}
                >
                  {showApprove
                    ? homeNumbers.approval
                    : nFormatter(homeNumbers.approval)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowRejected((prev) => !prev)}
            >
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <Icon>close</Icon>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <p className={classes.cardCategory}>Rejected Requests</p>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowRejected(true)}
                  onMouseLeave={() => setShowRejected(false)}
                >
                  {showRejected
                    ? homeNumbers.rejected
                    : nFormatter(homeNumbers.rejected)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowExpired((prev) => !prev)}
            >
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Icon>timer_off</Icon>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <p className={classes.cardCategory}>Expired Requests</p>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowExpired(true)}
                  onMouseLeave={() => setShowExpired(false)}
                >
                  {showExpired
                    ? homeNumbers.expiring
                    : nFormatter(homeNumbers.expiring)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
