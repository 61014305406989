import * as actionsType from "../actions/actionsType";

const vouchersReducer = (
  state = {
    managerVoucher: null,
    searchMessage: null,
    isSearching: false,
    vouchers: null,
    voucher: null,
    companies: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    case actionsType.GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
      };
    case actionsType.GET_VOUCHER_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionsType.VOUCHER_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.payload.loading,
        searchMessage: action.payload.message,
      };
    case actionsType.SET_MANAGER_VOUCHER:
      return { ...state, managerVoucher: action.payload };
    default:
      return state;
  }
};

export default vouchersReducer;
