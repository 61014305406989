import * as api from "../api";
import * as actionsType from "./actionsType";

export const getBrands = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANDS,
      payload: null,
    });
    const { data } = await api.getBrands(formData);
    dispatch({ type: actionsType.GET_BRANDS, payload: data });
    dispatch(getAllCategories(history));
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteBrand = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteBrand({ ids: idsArray });
    dispatch(getBrands(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Brand deleted Successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getAllCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANDS_CATEGORIES,
      payload: null,
    });
    const { data } = await api.getAllCategories();

    dispatch({
      type: actionsType.GET_BRANDS_CATEGORIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createBrand = (newData, formData, history) => async (dispatch) => {
  try {
    await api.createBrand(newData);
    dispatch(getBrands(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Brand has been created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateBrand = (newData, formData, history) => async (dispatch) => {
  try {
    await api.updateBrand(newData);
    dispatch(getBrands(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Brand has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
