import * as api from "../api";
import * as actionsType from "./actionsType";

export const getCategories = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CATEGORIES,
      payload: null,
    });
    const { data } = await api.getCategories(formData);
    dispatch({ type: actionsType.GET_CATEGORIES, payload: data });
    dispatch(getCompanies(history));
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteCategory = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteCategory({ ids: idsArray });
    dispatch(getCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Category deleted Successfully" },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
export const changeStatusCategory = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.changeStatusCategory({ ids: idsArray });
    dispatch(getCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Category status Changed Successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CATEGORIES_COMPANIES,
      payload: null,
    });
    const { data } = await api.getAllCompanies();

    dispatch({
      type: actionsType.GET_CATEGORIES_COMPANIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createCategory = (newData, formData, history) => async (
  dispatch
) => {
  try {
    const formNewData = new FormData();
    formNewData.append("name", newData.name);
    formNewData.append("name_ar", newData.name_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    formNewData.append("image", newData.image);
    formNewData.append("company_ids", newData.company_ids);

    await api.createCategory(formNewData);
    dispatch(getCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Category has been created successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateCategory = (newData, formData, history) => async (
  dispatch
) => {
  try {
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("name", newData.name);
    formNewData.append("name_ar", newData.name_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    if (newData.image) {
      formNewData.append("image", newData.image);
    }
    formNewData.append("company_ids", newData.company_ids);
    await api.updateCategory(formNewData);
    dispatch(getCategories(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Category has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
