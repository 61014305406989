import React, { useState } from "react";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/Shared/CustomInput/CustomInput.js";
import Button from "components/Shared/CustomButtons/Button.js";

import useStyles from "./VoucherInputStyle";

const VoucherInput = ({ searchCode }) => {
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const setSearchHandler = (e) => {
    setSearch(e.target.value);
  };

  const onEnterPressed = (e) => {
    if (e.keyCode === 13) {
      searchCode(search);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchInputContainer}>
        <CustomInput
          formControlProps={{
            className: classes.searchInput
          }}
          inputProps={{
            placeholder: "Search by voucher code",
            inputProps: {
              "aria-label": "Search by voucher code"
            },
            onChange: setSearchHandler,
            onKeyDown: onEnterPressed,
            value: search
          }}
        />
        <Button
          className={classes.searchButton}
          color="primary"
          aria-label="edit"
          justIcon
          round
          onClick={() => searchCode(search)}
        >
          <Search />
        </Button>
      </div>
    </div>
  );
};

export default VoucherInput;
