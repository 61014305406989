import * as actionsType from "../actions/actionsType";

const authReducer = (state = { authData: null, message: null }, action) => {
  switch (action.type) {
    case actionsType.AUTH:
      return { ...state, authData: action ? action.payload : false };
    case actionsType.LOGOUT:
      return { ...state, authData: null };
    default:
      return state;
  }
};

export default authReducer;
