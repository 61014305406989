import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
export default function Companies({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchHandler,
  searchValue,
  selectedSearchBy,
  handleSearchByChange,

  showRecycables,
  editCompany,
  addCompany,
  setFirstVoucher,
  deleteCompanies,
  resetCompanies
}) {
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to Delete this company ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                deleteCompanies(id);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Delete
            </Button>
            <Button
              color="info"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those Companies ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                deleteCompanies(selected);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Delete
            </Button>
            <Button
              color="info"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const handleFirstVoucher = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>
            Are You sure want to set sponsor first voucher this company ?{" "}
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                setFirstVoucher(id);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Set
            </Button>
            <Button
              color="info"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const handleReset = () => {
    if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to reset those Companies ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                resetCompanies(selected);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Delete
            </Button>
            <Button
              color="info"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Button
        color="primary"
        size="lg"
        onClick={() => handleReset()}
        style={{ margin: "0 10px" }}
      >
        Reset
      </Button>
      <Button
        color="danger"
        size="lg"
        onClick={() => handleDelete()}
        style={{ margin: "0 10px" }}
      >
        Delete
      </Button>
    </div>
  );

  const toolbarButtons = (
    <Button color="primary" onClick={addCompany}>
      Add new Company
    </Button>
  );

  const searchByOptions = [
    {
      value: "name",
      label: "Name"
    },
    {
      value: "name_ar",
      label: "Name Arabic"
    }
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Id"
    },
    {
      id: "name",
      sortable: true,
      label: "Name"
    },
    {
      id: "name_ar",
      sortable: true,
      label: "Name Arabic"
    },
    {
      id: "collectable_collected_branches",
      sortable: false,
      label: "Recycables Collected"
    },
    {
      id: "logo",
      sortable: false,
      label: "Logo"
    },
    {
      id: "icon",
      sortable: false,
      label: "Icon"
    },
    {
      id: "sponsorFirstVoucher",
      sortable: true,
      label: "Sponsor First Voucher"
    },
    { id: "options", sortable: false, label: "Options" }
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  style={{
                    color: primaryColor[1],
                    "&:checked": {
                      color: primaryColor[1]
                    }
                  }}
                  color="default"
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {"# " + row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name_ar}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectable_collected_branches}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div style={{ width: "100px", margin: "0 auto" }}>
                  <img
                    src={row.logo_full_path}
                    alt={row.name}
                    style={{ width: "100%" }}
                  />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div style={{ width: "50px", margin: "0 auto" }}>
                  <img
                    src={row.icon_full_path}
                    alt={row.name}
                    style={{ width: "100%" }}
                  />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_active_rejst_voucher === "1" ? (
                  <IconButton
                    aria-label="deselect first voucher"
                    onClick={() => handleFirstVoucher(row.id)}
                  >
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="select first voucher"
                    onClick={() => handleFirstVoucher(row.id)}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Tooltip title="Edit Merchant">
                      <IconButton
                        aria-label="edit"
                        onClick={() => editCompany(row.id)}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete company">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete([row.id])}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Tooltip title="open collectable details">
                    <IconButton
                      aria-label="show"
                      onClick={() => showRecycables(row.id)}
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={8} align="center">
            No Company Found
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
