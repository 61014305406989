import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@material-ui/core";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const CreateNewUser = ({ companies, createHandler }) => {
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };
  const [file, setFile] = useState("");
  const formik = useFormik({
    initialValues: {
      company_id: "",
      file: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useStyles();

  const handleSubmit = (values) => {
    const result = { ...values };
    if (file !== "") {
      result.file = file;
      createHandler(result);
    }
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* file xls, xlsx, csv */}
        <Grid>
          <InputLabel className={classes.label}>File:</InputLabel>
          <input
            id="file"
            onChange={handleFileChange}
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Grid>
        {/* company Id */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Merchant:</InputLabel>
          <Select
            id="company_id"
            name="company_id"
            label="Merchant"
            className={classes.selectInput}
            value={formik.values.company_id}
            onChange={formik.handleChange}
            multiple={false}
            inputProps={{ "aria-label": "Without label" }}
            // error={merchantsError}
          >
            {companies.map((comp) => (
              <MenuItem key={comp.id} value={comp.id}>
                <ListItemText primary={comp.name} />
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateNewUser;
