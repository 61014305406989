import {
  defaultFont,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";

const recyclerStyle = {
  form: {
    margin: "10px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: primaryColor[1] + "!important",
  },
  labelError: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: "red",
  },
  selectInput: {
    width: "100%",
  },
  customCheckbox: {
    color: primaryColor[1],
    display: "block",
    "&:checked": {
      color: primaryColor[1],
    },
  },
  customRadio: {
    color: primaryColor[1],
    "&$checked": {
      color: primaryColor[1],
    },
  },
  checked: {},
  chip: {
    backgroundColor: primaryColor[1],
    color: "white",
    height: "100%",
  },
  submit: {
    margin: "10px auto",
  },
  switchContainer: {
    marginBottom: "20px",
  },
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: primaryColor[1],
    },
    "&$checked + $track": {
      backgroundColor: primaryColor[0],
    },
  },
  checked: {},
  track: {},
};

export default recyclerStyle;
