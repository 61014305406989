import React from "react";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import useStyles from "./VoucherInfoStyle";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";

const VoucherInfo = ({ voucherInfo, handleUse }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleVoucherUse = (code) => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>
          Are You sure to Redeem this Voucher ? this operation can't be undo{" "}
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Button
            color="success"
            size="lg"
            onClick={() => {
              handleUse(code);
              setOpenModal(false);
            }}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Redeem
          </Button>
          <Button
            color="info"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainInfoContainer}>
        <div className={classes.contentContainer}>
          <span className={classes.label}>Client name:</span>
          <span className={classes.content}>{voucherInfo.client_name}</span>
        </div>
        <div className={classes.contentContainer}>
          <span className={classes.label}>Voucher Code:</span>
          <span className={classes.content}>{voucherInfo.code}</span>
        </div>
        <div className={classes.contentContainer}>
          <span className={classes.label} style={{ display: "block" }}>
            Voucher description:
          </span>
          <span className={classes.content} style={{ marginLeft: "20px" }}>
            {voucherInfo.description}
          </span>
        </div>
        {/* <div className={classes.contentContainer}>
          <span className={classes.label}>Voucher Valid:</span>
          <span className={classes.content}>
            {voucherInfo.valid_till + " Days"}
          </span>
        </div> */}
        <div className={classes.contentContainer}>
          <span className={classes.label}>Voucher Discount:</span>
          <span className={classes.content}>
            {voucherInfo.type_discount === "percentage"
              ? voucherInfo.discount_percentage + "%"
              : voucherInfo.discount_fixed}
          </span>
        </div>
        <div
          style={{ marign: "20px auto", width: "100%", textAlign: "center" }}
        >
          <Button
            color="primary"
            size="lg"
            disabled={voucherInfo.is_used === 1}
            onClick={
              voucherInfo.is_used === 0
                ? () => handleVoucherUse(voucherInfo.code)
                : null
            }
          >
            {voucherInfo.is_used === 0
              ? "redeem Voucher"
              : "Voucher already redeemed"}
          </Button>
        </div>
      </div>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default VoucherInfo;
