import React, { useState } from "react";
import { Grid, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./CompanyStyle";

const useStyles = makeStyles(styles);
const sendTypeList = ["DropOff", "Pickup"];
export default function SendTypeInput({
  sendType,
  onChangeHandler,
  error,
  helperText
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    setOpen(false);
    onChangeHandler(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <InputLabel className={error ? classes.labelError : classes.label}>
            Send Type:
          </InputLabel>
          <Select
            error={error}
            id="type_send"
            name="type_send"
            label="Send Type"
            className={classes.selectInput}
            value={sendType}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={handleChange}
            multiple={true}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
            // error={merchantsError}
          >
            {sendTypeList.map((st) => (
              <MenuItem key={st} value={st}>
                <Checkbox
                  checked={sendType.indexOf(st) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={st} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={error ? { color: "red" } : {}}>
            {helperText}
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
}
