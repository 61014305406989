import * as actionsType from "../actions/actionsType";

const homeReducer = (
  state = { message: null, homeNumbers: null, managerHomeNumbers: null },
  action
) => {
  switch (action.type) {
    case actionsType.GET_MANAGER_HOME:
      return { ...state, managerHomeNumbers: action.payload };
    case actionsType.GET_HOME:
      return { ...state, homeNumbers: action.payload };
    case actionsType.REMOVE_MESSAGE:
      return { ...state, message: null };
    case actionsType.SET_MESSAGE:
      return { ...state, message: action.payload };
    default:
      return state;
  }
};

export default homeReducer;
