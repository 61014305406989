import * as api from "../api";
import * as actionsType from "./actionsType";

export const getCompanies = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: null,
    });
    const { data } = await api.getCompanies(formData);
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const deleteCompany = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.deleteCompany({
      ids: idsArray,
    });
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: null,
    });
    const { data } = await api.getCompanies(formData);
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Company deleted Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const resetCompany = (idsArray, formData, history) => async (
  dispatch
) => {
  try {
    await api.resetCompany({
      ids: idsArray,
    });
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: null,
    });
    const { data } = await api.getCompanies(formData);
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Company reseted Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const setFirstVoucher = (id, formData, history) => async (dispatch) => {
  try {
    await api.selectToFirstVoucherCompany({
      id,
    });
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: null,
    });
    const { data } = await api.getCompanies(formData);
    dispatch({
      type: actionsType.GET_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "setting changed Successfully",
      },
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const clearCompany = () => (dispatch) => {
  dispatch({
    type: actionsType.GET_COMPANY,
    payload: null,
  });
};

export const getCompany = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: null,
    });
    dispatch({
      type: actionsType.GET_MAIN_COMPANIES,
      payload: null,
    });
    const companyRes = await api.getCompany(id);
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: companyRes.data.data,
    });
    const mainCompaniesRes = await api.getMainAccountType();
    dispatch({
      type: actionsType.GET_MAIN_COMPANIES,
      payload: mainCompaniesRes.data,
    });
    const companyNumbers = await api.getCompanyAnalysisCollectables(id);
    dispatch({
      type: actionsType.GET_COMPANY_NUMBERS,
      payload: companyNumbers.data.data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getMainCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_MAIN_COMPANIES,
      payload: null,
    });
    const { data } = await api.getMainAccountType();

    dispatch({
      type: actionsType.GET_MAIN_COMPANIES,
      payload: data,
    });
  } catch (error) {
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const createCompany = (newData, history) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("name", newData.name);
    formData.append("name_ar", newData.name_ar);
    formData.append("address", newData.address);
    formData.append("address_ar", newData.address_ar);
    formData.append("link_website", newData.link_website);
    formData.append("phone", newData.phone);
    formData.append("description", newData.description);
    formData.append("description_ar", newData.description_ar);
    formData.append("logo", newData.logo);
    formData.append("icon", newData.icon);
    formData.append("background_coupon_en", newData.background_coupon_en);
    formData.append("background_coupon_ar", newData.background_coupon_ar);
    formData.append("coupon_text_color", newData.coupon_text_color);
    formData.append("type_discount", newData.type_discount);
    formData.append("type_account", newData.type_account);
    formData.append("type_send", newData.type_send);
    formData.append("cap", newData.cap);
    formData.append("reminder", newData.reminder);
    formData.append("discount_fixed", newData.discount_fixed);
    formData.append("discount_percentage", newData.discount_percentage);
    formData.append("validity_period", newData.validity_period);
    formData.append("num_user_voucher", newData.num_user_voucher);
    formData.append("companies_ids", newData.companies_ids);
    formData.append("is_single_voucher", newData.is_single_voucher);
    if (newData.is_single_voucher === 1) {
      formData.append("voucher_single_code", newData.voucher_single_code);
      formData.append("count_voucher", newData.count_voucher);
    } else {
      // formData.append("count_voucher", 0);
    }
    await api.createCompany(formData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Merchant has been created successfully",
      },
    });
    history.push("/admin/merchants/merchants");
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const updateCompany = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: null,
    });
    const formData = new FormData();
    formData.append("id", newData.id);
    formData.append("name", newData.name);
    formData.append("name_ar", newData.name_ar);
    formData.append("address", newData.address);
    formData.append("address_ar", newData.address_ar);
    formData.append("link_website", newData.link_website);
    formData.append("phone", newData.phone);
    formData.append("description", newData.description);
    formData.append("description_ar", newData.description_ar);
    formData.append("is_single_voucher", newData.is_single_voucher);
    if (newData.is_single_voucher === 1) {
      formData.append("voucher_single_code", newData.voucher_single_code);
      formData.append("count_voucher", newData.count_voucher);
    } else {
      // formData.append("count_voucher", 0);
    }
    if (newData.logo) {
      formData.append("logo", newData.logo);
    }
    if (newData.icon) {
      formData.append("icon", newData.icon);
    }
    if (newData.background_coupon_en) {
      formData.append("background_coupon_en", newData.background_coupon_en);
    }
    if (newData.background_coupon_ar) {
      formData.append("background_coupon_ar", newData.background_coupon_ar);
    }
    formData.append("coupon_text_color", newData.coupon_text_color);
    formData.append("type_discount", newData.type_discount);
    formData.append("type_account", newData.type_account);
    formData.append("type_send", newData.type_send);
    formData.append("cap", newData.cap);
    formData.append("reminder", newData.reminder);
    formData.append("discount_fixed", newData.discount_fixed);
    formData.append("discount_percentage", newData.discount_percentage);
    formData.append("validity_period", newData.validity_period);
    formData.append("num_user_voucher", newData.num_user_voucher);
    formData.append("companies_ids", newData.companies_ids);
    await api.updateCompany(formData);
    const companyRes = await api.getCompany(newData.id);
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: companyRes.data.data,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Merchant has been updated successfully",
      },
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};

export const getCompanyCollectables = (formData, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANY_COLLECTABLES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COMPANY_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.getCompanyCollectables(formData);
    console.log(data);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    const companyNumbers = await api.getCompanyAnalysisCollectables(
      formData.company_id
    );
    dispatch({
      type: actionsType.GET_COMPANY_NUMBERS,
      payload: companyNumbers.data.data,
    });
    dispatch({
      type: actionsType.GET_COMPANY_COLLECTABLES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COMPANY_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
  } catch (error) {
    console.log(error);
    let errorMessage =
      error.response.status + " " + error.response.data.message;
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "danger", text: errorMessage },
    });
    if (error.response.status === 401 || error.response.status === 403) {
      history.push("/");
    }
  }
};
