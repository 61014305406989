import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Shared/CustomTable/Table";
import EnhancedTableHead from "./TableHead";
import EnhancedTableToolbar from "./TableToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function EnhancedTable({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchHandler,
  searchValue,
  selectedSearchBy,
  handleSearchByChange,
  tableCells,
  selected,
  setSelected,
  headCells,
  isSelectable,
  selectedToolbarButtons,
  toolbarButtons,
  showSearchBy,
  searchByOptions,
  showFilter,
  filterTitle,
  selectedFilter,
  handleFilterChange,
  filterOptions,
  handleFilterReset
}) {
  const classes = useStyles();
  const [search, setSearch] = React.useState(searchValue);

  const handleRequestSort = (event, property) => {
    if (property !== "options") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setPage(1);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const setSearchHandler = (e) => {
    setSearch(e.target.value);
  };
  const clearSearchHandler = () => {
    setSearch("");
    searchHandler("");
  };

  const tableToolbar = (
    <EnhancedTableToolbar
      numSelected={selected.length}
      setSearchHandler={setSearchHandler}
      searchHandler={() => {
        searchHandler(search);
      }}
      clearSearchHandler={clearSearchHandler}
      searchValue={search}
      selectedSearchBy={selectedSearchBy}
      handleSearchByChange={handleSearchByChange}
      selectedToolbarButtons={selectedToolbarButtons}
      toolbarButtons={toolbarButtons}
      showSearchBy={showSearchBy}
      searchByOptions={searchByOptions}
      showFilter={showFilter}
      filterTitle={filterTitle}
      selectedFilter={selectedFilter}
      handleFilterChange={handleFilterChange}
      filterOptions={filterOptions}
      handleFilterReset={handleFilterReset}
    />
  );

  const tableHead = (
    <EnhancedTableHead
      classes={classes}
      numSelected={selected.length}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={handleSelectAllClick}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
      headCells={headCells}
      isSelectable={isSelectable}
    />
  );
  return (
    <React.Fragment>
      <Table
        tableHead={tableHead}
        tableToolbar={tableToolbar}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      >
        {tableCells}
      </Table>
    </React.Fragment>
  );
}
