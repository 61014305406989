import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import SubCategory from "./SubCategory";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: "#19793a",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
}));

export default function SubCategories({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  createHandler,
  updateHandler,
  deleteHandler,
  categories,
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(true);
  const [selectedSub, setSelectedSub] = React.useState(null);
  const [modalContent, setModalContent] = React.useState(null);

  const openEditModal = (sub) => {
    setIsCreate(false);
    setSelectedSub(sub);
    setOpenCreateModal(true);
  };

  const openCreateModalHandler = () => {
    setIsCreate(true);
    setSelectedSub(null);
    setOpenCreateModal(true);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete this Sub-Category ? </h5>
          <div>
            <Button color="danger" size="lg" onClick={() => deleteHandler(id)}>
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those Sub-Categories ? </h5>
          <div>
            <Button
              color="danger"
              size="lg"
              onClick={() => deleteHandler(selected)}
            >
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button color="danger" aria-label="delete" onClick={() => handleDelete()}>
        Delete Selected
      </Button>
    </div>
  );

  const toolbarButtons = (
    <div>
      <Button color="primary" onClick={openCreateModalHandler}>
        Add new Sub Category
      </Button>
    </div>
  );

  const searchByOptions = [
    {
      value: "name",
      label: "Name",
    },
    {
      value: "name_ar",
      label: "Name Arabic",
    },
    {
      value: "category_name",
      label: "Category Name",
    },
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "ID",
    },
    {
      id: "name",
      sortable: true,
      label: "Name",
    },
    {
      id: "name_ar",
      sortable: true,
      label: "Name Arabic",
    },
    {
      id: "priority",
      sortable: true,
      label: "Priority",
    },
    {
      id: "min_quantity",
      sortable: false,
      label: "Min Quantity",
    },
    {
      id: "categories",
      sortable: true,
      label: "Categories",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  style={{
                    color: primaryColor[1],
                    "&:checked": {
                      color: primaryColor[1],
                    },
                  }}
                  color="default"
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name_ar}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.priority}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.min_quantity}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.categories.map((cat, index) => (
                  <span key={index}>{" - " + cat}</span>
                ))}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Edit Sub Category">
                    <IconButton
                      aria-label="edit"
                      onClick={() => openEditModal(row)}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Sub Category">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete([row.id])}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={8} align="center">
            No Sub Categories
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
      <Modal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        customStyle={{
          width: "100%",
          maxWidth: "500px",
          maxHeight: "500px",
          padding: 0,
          overflowY: "hidden",
        }}
      >
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>
            {isCreate ? "Add New Sub Category" : "Edit Sub Category"}
          </span>
          <IconButton
            aria-label="delete"
            onClick={() => setOpenCreateModal(false)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <SubCategory
            categories={categories}
            sub={selectedSub}
            isCreate={isCreate}
            createHandler={createHandler}
            updateHandler={updateHandler}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
