import React from "react";
import Modal from "components/Shared/CustomModal/CustomModal";
import Button from "components/Shared/CustomButtons/Button";
import useStyles from "./CollectableStyle";

const Images = ({ collectable }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const onClickHandler = (image) => {
    setModalContent(
      <div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="transparentBlack"
            size="lg"
            onClick={() => setOpenModal(false)}
          >
            Close
          </Button>
        </div> */}
        <div style={{ width: "100%", height: "100%" }}>
          <img
            src={image}
            alt="Recycable"
            className={classes.modalImage}
            onClick={() => setOpenModal(false)}
          />
        </div>
      </div>
    );
    setOpenModal(true);
  };
  return (
    <>
      <div className={classes.otherInfoContainer} style={{ overflowX: "auto" }}>
        {collectable.images.map((image, index) => (
          <div key={index} className={classes.imageContainer}>
            <img
              alt={collectable.client_name}
              src={image.original_path}
              className={classes.image}
              onClick={() => onClickHandler(image.original_path)}
            />
          </div>
        ))}
      </div>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </>
  );
};

export default Images;
