import * as actionsType from "../actions/actionsType";

const mobileReducer = (state = { mobileUpdate: null }, action) => {
  switch (action.type) {
    case actionsType.GET_MOBILE_UPDATE:
      return { ...state, mobileUpdate: action.payload };
    default:
      return state;
  }
};

export default mobileReducer;
