import * as actionsType from "../actions/actionsType";

const categoriesReducer = (
  state = {
    categories: null,
    companies: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case actionsType.GET_CATEGORIES_COMPANIES:
      return {
        ...state,
        companies: action.payload
      };
    default:
      return state;
  }
};

export default categoriesReducer;
