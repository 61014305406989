import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "components/Shared/CustomTable/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";

import EnhancedTableHead from "./TableHead";
import EnhancedTableToolbar from "./TableToolbar";
import CreateNewUser from "./CreateNewUser";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: "#19793a",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
}));

export default function EnhancedTable({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  deleteUser,
  createUser,
  selectedSearchBy,
  handleSearchByChange,
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [search, setSearch] = React.useState(searchValue);
  const [modalContent, setModalContent] = React.useState(null);

  const handleRequestSort = (event, property) => {
    if (property !== "options" && property !== "status") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setPage(1);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete this user ? </h5>
          <div>
            <Button color="danger" size="lg" onClick={() => deleteUser(id)}>
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those users ? </h5>
          <div>
            <Button
              color="danger"
              size="lg"
              onClick={() => deleteUser(selected)}
            >
              Delete
            </Button>
            <Button color="info" size="lg" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const setSearchHandler = (e) => {
    setSearch(e.target.value);
  };

  const clearSearchHandler = () => {
    setSearch("");
    searchHandler("");
  };

  const tableToolbar = (
    <EnhancedTableToolbar
      numSelected={selected.length}
      handleDelete={handleDelete}
      setSearchHandler={setSearchHandler}
      clearSearchHandler={clearSearchHandler}
      searchHandler={() => {
        searchHandler(search);
      }}
      searchValue={search}
      openCreateModal={() => setOpenCreateModal(true)}
      selectedSearchBy={selectedSearchBy}
      handleSearchByChange={handleSearchByChange}
    />
  );
  const tableHead = (
    <EnhancedTableHead
      classes={classes}
      numSelected={selected.length}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={handleSelectAllClick}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
    />
  );
  return (
    <React.Fragment>
      <Table
        tableHead={tableHead}
        tableToolbar={tableToolbar}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      >
        {rows.length !== 0 ? (
          rows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow key={row.id} hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleClick(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.name}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.email}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.role}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.branch_name}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                >
                  {row.status === "active" ? (
                    <Tooltip title="user's email activated">
                      <i className="fas fa-user-check"></i>
                    </Tooltip>
                  ) : (
                    <Tooltip title="user's email not activated">
                      <i className="fas fa-user-times"></i>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell component="th" padding="normal" align="center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Delete Client">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete([row.id])}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={8} align="center">
              No Users CLients
            </TableCell>
          </TableRow>
        )}
      </Table>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
      <Modal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        customStyle={{ width: "100%", maxWidth: "500px", padding: 0 }}
      >
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>
            Add New Branche Agent
          </span>
          <IconButton
            aria-label="delete"
            onClick={() => setOpenCreateModal(false)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <CreateNewUser createUser={createUser} />
        </div>
      </Modal>
    </React.Fragment>
  );
}
