import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Table from "components/Admin/Collectables/Collectables";

import { useDispatch, useSelector } from "react-redux";
import { getBranchNotCollected } from "store/actions/branches";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap"
  },
  loadingContainer: {
    textAlign: "center"
  },
  loading: {
    color: "#19793a",
    width: "100px!important",
    height: "100px!important"
  }
});

const useStyles = makeStyles(styles);

const BranchNotCollected = () => {
  const { id } = useParams();
  const { notCollected } = useSelector((state) => state.branches);
  const dispatch = useDispatch();
  const [page, setPage] = useState(notCollected ? notCollected.pag.page : 1);
  const [amount, setAmount] = useState(
    notCollected ? notCollected.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    notCollected ? notCollected.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    notCollected ? notCollected.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    notCollected ? notCollected.pag.search_by : "model_name"
  );
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getBranchNotCollected(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          id: id
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/merchants/branches/recycable/" + id);
  };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparentBlack"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ margin: "auto", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              branch's Not Collected Recycables
            </h4>
          </CardHeader>
          <CardBody>
            {notCollected ? (
              <Table
                rows={notCollected.data}
                totalRows={notCollected.pag.total}
                rowsPerPage={amount}
                setRowsPerPage={setAmount}
                order={orderType}
                setOrder={setOrderType}
                orderBy={orderField}
                setOrderBy={setOrderField}
                page={page - 1}
                setPage={setPage}
                showRecycable={showRecycableHandler}
                searchHandler={setSearchValue}
                searchValue={searchValue}
                selectedSearchBy={searchBy}
                handleSearchByChange={setSearchBy}
              />
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress className={classes.loading} />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BranchNotCollected;
